$white: rgba(255,255,255);
$primary-color: gold;
@font-face {
    font-family: 'Countdown';
    src: url('../src/assets/fonts/countdown.ttf');
}

img{
    background-color: #fff1;
}

button{
    cursor: pointer;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Open sans", sans-serif;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
}

#root{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    transform: scale(1);
    z-index: 10;
}

.app{
    z-index: 100;
    opacity: 1;
    transition: all 0.5s ease;
    position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    background-color: #e9e9e9ea;
    overflow: hidden;
    transform: scale(1);
    @media screen and (min-width: 2000px) {
        width: 95%;
        max-width: 1700px;
        height: 95%;
        max-height: 1700px;
        border-radius: 20px;
        box-shadow: 0px 0px 50px #0003;
    }
    
    @media screen and (max-width: 660px) {
        background-color: #e9e9e9ea;
    }

    .no-network{
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 80px;
        width: 80px;
        background-color: #222a;
        color: #fff;
        z-index: 15;
        backdrop-filter: blur(10px);
        transition: all 0.5s ease;
        font-size: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        opacity: 0;
        pointer-events: none;

        &.show{
            pointer-events: all;
            opacity: 1;
        }

        @media screen and (max-width: 660px){
            height: 70px;
            width: 70px;
            font-size: 26px;
        }

        @media screen and (max-width: 450px){
            height: 60px;
            width: 60px;
            font-size: 23px;
        }

        & .info{
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            max-width: 80vw;
            width: 300px;
            padding: 20px;
            background-color: #222;
            border-radius: inherit;
            right: 0;
            z-index: 1;
            bottom: 0;
            transition: all 0.5s ease;
            opacity: 0;
            pointer-events: none;
            
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items: center;
            gap: 10px;
            text-align: left;
            h5{
                width: 100%;
            }

            p{
                font-size: 17px;

                span{
                    padding: 0 5px;
                    margin: 0 5px;
                    background-color: rgb(255,166, 0);
                    transition: all 0.5s ease;
                    cursor:pointer;
                    color: #000;

                    &:hover{
                        background-color: tomato;
                        color: #fff;
                    }
                }
            }
        }

        &:hover{
            background-color: #222;

            .info{
                opacity: 1;
                pointer-events: all;

            }
        }

        svg{
            animation: no-network 0.5s ease infinite forwards alternate;
            @keyframes no-network{
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
        }
    }

    .menu{
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        height: 100%;
        max-width: 500px;
        width: 80%;
        min-width: 250px;
        background-color: #fff;
        transition: all 0.7s ease;
        transform: translateX(-120%);
        pointer-events: none;
        display: none;
        flex-direction: column;
        padding: 0px 5vw;
        overflow: hidden;
        // border-radius: 0px 20px 20px 0px;

        .heading{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;
            margin-bottom: 40px;
            font-family: "Raleway", monospace;

            a{
                display: flex;
                gap: 15px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #fff;
                background-color: rgb(255, 166, 0);
                padding: 12px 20px;
                min-height: 55px;
                border-radius: 15px;
                font-size: 19px;
                font-weight: 600;
                transition: all 0.5s ease;
                color: #000;
                color: #fff;
                font-family: "Raleway", monospace;

                &:hover{
                    background-color: #222;
                    padding: 12px 40px;
                }
                
                img{
                    // background-color: #000;
                    // box-shadow: 0px 0px 0px 2px #fff;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    margin-left: -5px;
                    border-radius: 100px;
                }

                svg{
                    font-size: 20px;
                }
            }

            div{
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .links-holder{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 50px;

            a{
                svg{
                    transition: all 0.5s ease;
                    font-size: 19px;
                }

                font-size: 18px;
                color: #666;
                width: 100%;
                padding: 15px;
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                transition: all 0.5s ease;
                // border-radius: 10px;
                position: relative;
                border-left: 3px solid transparent;

                &:hover{
                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                    color: rgb(255, 183, 0);
                }

                &.active{
                    border-left-color: rgb(255, 166, 0);
                    background-color: rgba(255, 183, 0, 0.2);
                    // color: #fff;
                    // color: rgb(255, 183, 0);
                }
            }
        }

        .logout__link{
            position: fixed;
            bottom: 20px;
            left: 10px;
            height: 45px;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            color: #fff;
            background-color: #444;
            font-size: 20px;
            font-weight: bold;
            transition: all 0.5s ease;
            cursor: pointer;
            transform: scaleX(-1);

            &::before{
                content: "Logout";
                position: absolute;
                background-color: red;
            }
            
            svg{
                transition: all 0.5s ease;
            }

            &::before{
                pointer-events: none;
                content: "Logout!";
                position: absolute;
                left: 75px;
                height: 100%;
                padding: 0 10px;
                font-size: 14px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.5s ease;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: inherit;
                background-color: rgb(131, 21, 21);
                color: #fff;
                backdrop-filter: blur(10px);
                display: none;
            }

            &:hover{
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
                background-color: rgb(131, 21, 21);
                svg{
                    color: #fff !important;
                }
            }
        }

        @media screen and (max-width: 660px) {
            display: flex;
        }
    }

    .backdrop{
        position: fixed;
        z-index: 98;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: #000a;
        backdrop-filter: blur(4px);
        transition: all 0.7s ease;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        display: none;
        display: flex;
    }

    &.show-menu{
        .menu{
            transform: translateX(0);
            pointer-events: all;
        }

        .home, .oops, .cart-big, .shop, .signup, .dashboard, .search, .become-a-seller-big, .seller-big, .faqs, .account, .settings, .product-big{
            transform: translateY(0px) translateX(40px);
        }
        
        .backdrop{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    .confirm{
        position: fixed;
        bottom: 20px;
        width: 90%;
        text-align: center;
        left: 5%;
        transition: all 0.5s ease;
        display: flex;
        z-index: 120;
        justify-content: center;
        align-items: center;
        transform: translateY(calc(110% + 50px));
        pointer-events: none;
        flex-direction: column;
        .container{
            gap: 20px;
            width: 100%;
            height: fit-content;
            padding: 20px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            flex-direction: column;
            background-color: #fff;
        }

        .info{
            position: absolute;
            top: -20px;
            padding: 10px 15px;
            font-size: 20px;
            font-weight: 600;
            font-family:"Raleway", monospace;
            background-color: #ffb700;
            color: #000;
            border-radius: 100px;
            display: none;
        }

        .close{
            display: none;
        }

        .content{
            font-size: 18px;
            font-weight: 500;
        }

        .action{
            width: 100%;
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .controls{
            width: 100%;
            display:flex;
            justify-content: center;
            align-items: center;
            max-width: 700px;
            background-color: #222;
            border-radius: 15px;
            padding: 3px;
            overflow: hidden;

            button{
                font-family: "Raleway", monospace;
                font-weight: 600;
                font-size: 18px;
                color: #fff;
                background-color: transparent;
                padding: 10px 20px;
                border: none;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:last-child{
                    background-color: #ffb700;
                    color: #000;
                }

                &.disabled{
                    opacity: 1;
                    cursor: not-allowed;
                    &::before{
                        content: "Coming soon";
                        position: absolute;
                        max-width: 250px;
                        min-width: 150px;
                        padding: 10px;
                        background-color: #000000fc;
                        backdrop-filter: blur(10px);
                        border-radius: 5px;
                        top: 20px;
                        transition: all 0.5s ease;
                        opacity: 0;
                        color: #fff;
                        border-radius: 5px;
                        pointer-events: none;
                    }

                    &:hover, &:focus{
                        &::before{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.confirming-payment{
        .backdrop{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .confirm.payment{
            transform: translateX(0);
            pointer-events: all;
        }
    }

    &.confirming-logout{
        .backdrop{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .confirm.logout{
            transform: translateX(0);
            pointer-events: all;
        }
    }

    .nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 12;
        display: flex;
        display: none;
        flex-direction: column; 
        backdrop-filter: blur(10px);
        background-color: #e9e9e9ea;

        @media screen and (max-width: 660px) {
            display: flex;
           
        }
        .sub-nav{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 5vw;
        }
        .logo{
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            font-size: 22px;
            background-color: rgb(255, 183, 0);
            background-color: #eeeeeea9;

            img{
                width: 80%;
                background-color: transparent;
                transform: translate(2px, 2px);
            }

            svg{
                transform: translate(-2px, 2px);
            }
        }

        &.in-search{
            .links-holder{
                a:first-child{
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        .links-holder{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            a{
                height: 45px;
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: #222;
                font-size: 15px !important;
                transition: all 0.5s ease;
            }

            .cart{
                color: #666;
                position: relative;
                border-radius: 50%;
                &:hover{
                    background-color: #0001;
                }


                .number{
                    position: absolute;
                    background-color: rgb(255, 166, 0);
                    color: #333;
                    font-size: 12px;
                    font-weight: 700;
                    padding: 2px 6px;
                    border-radius: 100px;
                    transition: all 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: -1px;
                    right: -1px;
                }
            }
            
            .hamburger{
                font-size: 24px;
                height: 45px;
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: #222;
                transition: all 0.5s ease;
                cursor: pointer;
                
                &:hover{
                    background-color: rgba(255, 183, 0, 0.179);
                }
            }


        }

    }

    .preloader{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000e2;
        z-index: 100;
        backdrop-filter: blur(10px);
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        &.mini{
            display: none;
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            backdrop-filter: blur(0px);
            background-color: #000d;
        }

        .preloader-circle{
            width: 70px;
            height: 70px;
            border: 7px solid rgb(255, 249, 238);
            border-left-color: rgb(255, 166, 0);
            border-radius: 50%;
            animation: spin-preloader 1s linear forwards infinite;

            @media screen and (max-width: 660px) {
                border-width: 6px;
                width: 60px;
                height: 60px;
            }

            @media screen and (max-width: 450px) {
                border-width: 6px;
                width: 55px;
                height: 55px;
            }
        }

        @keyframes spin-preloader {
            0%{
                transform: rotate(-730deg);
            }
            100%{
                transform: rotate(0deg);
            }
        }
    }
    .toastify, .Toastify{
        animation-play-state: running !important;
    
        *{
            animation-play-state: running !important;
        }
    }

    &.loading{
        *{
            animation-play-state: paused !important;
        }
        .toastify, .Toastify{
            animation-play-state: running !important;
        
            *{
                animation-play-state: running !important;
            }
        }
        .preloader{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            *{
                animation-play-state: running !important;
            }
            
            &.mini{
                opacity: 1;
                display: none;
            }
            
        }

        .home, .cart-big, .shop, .settings, .dashboard, .account, .seller, .product-big, .signup, .auth, .search{
            animation-play-state: running !important;
        }
    }
    
    &.mini-loading{
        *{
            animation-play-state: paused !important;
        }
        .home, .cart-big, .shop, .settings, .dashboard, .account, .seller, .product-big, .signup, .auth, .search{
            animation-play-state: running !important;
        }
        .toastify, .Toastify{
            animation-play-state: running !important;
        
            *{
                animation-play-state: running !important;
            }
        }


        .preloader{
            *{
                animation-play-state: running !important;
            }

            &.mini{
                display: flex;
                pointer-events: all;
                animation: hide-mini 0.5s ease forwards;
                animation-delay: 0.5s;
            }

            @keyframes hide-mini{
                0%{
                    opacity: 1;
                }
                100%{
                    opacity: 0;
                }
            }
        }
    }

    .home, .Toastify, .toastify , .cart-big, .shop, .settings, .dashboard, .account, .seller, .product-big, .signup, .auth, .search{
        animation-play-state: running !important;
        animation-duration: 0.2s !important;
    }
    
    .header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        backdrop-filter: blur(10px);
        background-color: #e9e9e9ea;
        background-color: #e9e9e9ea;
    
        @media screen and (max-width: 660px) {
            display: none;
        }
        .sub-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 20px 0px;
            padding-left: 120px;

        }

        .search-bar{
            height: 45px;
            padding: 0 15px;
            width: 300px;
            max-width: 40vw;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 10px;
            background-color: #fffc;
            border-radius: 10px;
            color: #888;
            backdrop-filter: blur(10px);
            transition: all 0.5s ease;
            margin-right: 20px;

            &:hover{
                background-color: #fff;
            }

            input{
                height: 100%;
                width: 100%;
                border: none;
                background-color: transparent;
                outline: none;
            }

            svg{
                color: #000;
                cursor: pointer;
                transition: all 0.5s ease;
            }
        }

        .links-holder{
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            
            div{
                position: relative;
                border-radius: 50%;
                &:hover{
                    background-color: #0001;
                }
            }

            a, & > div{
                &.profile{
                    width: fit-content;
                    margin-right: 30px;
                    margin-left: 20px;
                    
                    img{
                        min-width: 45px;
                        min-height: 45px;
                        box-shadow: 0px 0px 10px #0003;
                        width: 45px;
                        height: 45px;
                        border: 3px solid rgb(255, 166, 0);
                        border-radius: 50%;
                        background-color: rgb(255, 235, 197);
                        color: transparent;
                    }

                }

                min-width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                color: #444;
                transition: all 0.5s ease;

                &.cart{
                    color: #666;
                    position: relative;
                    border-radius: 50%;
                    &:hover{
                        background-color: #0001;
                    }


                    .number{
                        position: absolute;
                        background-color: rgb(255, 166, 0);
                        color: #333;
                        font-size: 12px;
                        font-weight: 700;
                        padding: 2px 6px;
                        border-radius: 100px;
                        transition: all 0.5s ease;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: -1px;
                        right: -1px;
                    }
                }
            
                &.tologin{
                    padding: 0 15px;
                    justify-content: center;
                    position: relative;
                    background-color: rgb(255, 166, 0);
                    margin-right: 25px;
                    border-radius: 10px;
                    margin-left: 10px;
                    min-width: 120px;
                    gap: 8px;
                    color: #000;
                    font-weight: 500;
                    transition: all 0.5s ease;
                    cursor: default;

                    .down{
                        transition: all 0.5s ease;
                    }
                    
                    &:hover{
                        .actions{
                            transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                        }

                        .down{
                            transform: rotate(180deg);
                        }
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 200%;
                        background-color: transparent;
                    }

                    .actions{
                        position: absolute;
                        bottom: -100px;
                        background-color: rgb(255, 166, 0);
                        color: #000;
                        overflow: hidden;
                        border-radius: inherit;
                        width: 100%;
                        transition: all 0.5s ease;
                        transform: translateY(100%);
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;

                        a{
                            position: relative;
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 8px;
                            padding-left: 15px;
                            color: #000;
                            border-bottom: 2px solid #fff4;
                            transition: all 0.5s ease;

                            &:hover{
                                background-color: #fff4;
                            }

                            &:last-child{
                                border: 0px solid transparent;
                            }
                        }
                    }

                    span{
                        z-index: 1;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        width: 200px;
                        z-index: 0;
                        height: 200px;
                        border-radius: 50%;
                        background-color: #fff4;
                        transition: all 0.5s ease;
                        transform: scale(0);
                        opacity: 0;
                        display: none;
                    }

                    svg{
                        transition: all 0.5s ease;
                        z-index: 1;
                        &:first-child{
                            transform: translateY(2px);
                        }
                    }

                    &:hover{
                        &::before{
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &.hide-items{

            .search-bar{
                pointer-events: none;
                opacity: 0.2;
            }

            // .links-holder{
            //     a{
            //         opacity: 0.2;
            //         // visibility: hidden;
            //         pointer-events: none;

            //         &:last-child{
            //             opacity: 1;
            //             pointer-events: all;
            //         }
            //     }
            // }
        }
    }

    .sidebar{
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        background-color: $white;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 15px;
        align-items: center;

        @media screen and (max-width: 660px) {
            display: none;
        }

        .logo{
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            font-size: 22px;
            background-color: rgb(255, 183, 0);
            background-color: transparent;

            img{
                background-color: transparent;
                width: 80%;
                transform: translate(2px, 2px);
            }

            svg{
                transform: translate(-2px, 2px);
            }
        }

        .links-holder{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 70px;

            a{
                position: relative;
                svg{
                    transition: all 0.5s ease;

                    &:last-child{
                        position: absolute;
                        opacity: 0;
                    }
                }

                &.active{
                    svg{
                        opacity: 0;
                        &:last-child{
                            opacity: 1;
                        }
                    }
                }

                font-size: 21px;
                color: #aaa;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                transition: all 0.5s ease;
                border-radius: 10px;
                position: relative;

                &::before{
                    pointer-events: none;
                    content: "Home";
                    position: absolute;
                    left: 75px;
                    height: 100%;
                    padding: 0 10px;
                    font-size: 14px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: inherit;
                    background-color: #fffffffe;
                    backdrop-filter: blur(10px);
                }
                
                &:nth-child(2)::before{
                    content: "Search";
                }

                &:nth-child(2)::before{
                    content: "Shop";
                }

                &:nth-child(3)::before{
                    content: "Dashboard";
                }

                &:nth-child(4)::before{
                    content: "Profile";
                }

                &:nth-child(5)::before{
                    content: "Settings";
                }

                &:hover{
                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                    color: rgb(255, 183, 0);
                }

                &.settings__link{
                    &:hover{
                        svg{
                            transform: rotate(360deg);
                        }
                    }
                }

                &.active{
                    color: rgb(255, 183, 0);
                }
            }
        }

        .logout__link{
            position: fixed;
            bottom: 20px;
            height: 45px;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: #444;
            transform: scaleX(-1);
            font-size: 20px;
            font-weight: bold;
            transition: all 0.5s ease;
            cursor: pointer;
            
            svg{
                color: #fff !important;
                transition: all 0.5s ease;
            }

            &::before{
                pointer-events: none;
                content: "Logout!";
                position: absolute;
                right: 75px;
                height: 100%;
                padding: 0 10px;
                font-size: 14px;
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                transition: all 0.5s ease;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: inherit;
                background-color: rgb(131, 21, 21);
                color: #fff;
                transform: scaleX(-1);
                backdrop-filter: blur(10px);
            }

            &:hover{
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
                background-color: rgb(131, 21, 21);
                svg{
                    color: #fff !important;
                }
            }
        }
    }

    .home, .oops, .cart-big, .shop, .signup, .dashboard, .search, .become-a-seller-big, .seller-big, .faqs, .account, .settings, .product-big{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
        background-color: #e9e9e9 !important;
        animation: newPage 0.5s ease forwards;
        overflow: hidden;

        @media screen and (max-width: 660px) {
            background-color: #e9e9e9ea;
        }
    }

    @keyframes newPage {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .signup, .oops{
        display: flex;
        justify-content: flex-end;

        &.oops{
            .content{
                justify-content: center;
                align-items: center;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 0px;
                overflow: hidden;
                @media screen and (max-width: 660px) {
                    padding: 0 0;
                }
            }
        }
        .content{
            width: calc(100% - 120px);
            padding-top: 130px;
            padding-bottom: 70px;
            padding-right: 25px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            &::-webkit-scrollbar{
                width: 7px;
                height: 0px;
            }

            @media screen and (max-width: 660px) {
                padding: 0 5vw;
            }

            .not-found{
                box-sizing: border-box;
                // height: 100%;
                // width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: #fff;
                border-radius: 30px;
                padding: 30px;

                span{
                    line-height: 80px;
                    font-size: 120px;
                    font-weight: 500;
                    font-family: "Raleway", monospace;
                    color: rgb(210, 38, 38);
                }
                
                h2{
                    margin-top: 40px;
                    margin-bottom: 20px;
                }
                
                p{
                    max-width: 500px;
                    margin-bottom: 20px;
                }

                svg{
                    top: -30px;
                    left: -30px;
                    font-size: 100px;
                    transition: all 0.5s ease;
                    color: rgb(255, 183, 0);
                    position: absolute;
                    transform: rotate(-15deg);
                    animation: scale-up 1s ease forwards infinite alternate;
                }

                
                @media screen and (max-width: 850px) {
                    padding: 30px 20px;
                    transform: scale(0.8);
                }

                @keyframes scale-up {
                    0%{
                        scale: 0.4;
                    }

                    100%{
                        scale: 1;
                    }
                }

                a{
                    border-radius: 100px;
                    text-decoration: none;
                    color: #000;
                    font-weight: 700;
                    font-size: 20px;
                    font-family: "Raleway", monospace;
                    padding: 20px 30px;
                    background-color: rgb(255, 166, 0);
                    transition: all 0.5s ease;
                    position: relative;
                    overflow: hidden;

                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #000;
                        transform: translateX(-110%);
                        transition: all 0.5s ease;
                    }

                    p{
                        margin: 0;
                        position: relative;
                        z-index: 1;
                        transition: all 0.5s ease !important;
                        color: #000;
                    }

                    &:hover{
                        &:before{
                            transform: translateX(0);
                        }

                        p{
                            color: #fff;
                        }
                    }
                }
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-right: 5vw;
                padding-left: 5vw;
                padding-top: 100px;
                
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            &::-webkit-scrollbar-button{
                height: 50px;
            }

            &::-webkit-scrollbar-thumb{
                background-color: rgb(255, 166, 0);
                border-radius: 100px;
            }
            
            .card{
                max-width: 800px;
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
                flex-direction: column;
                border-radius: 15px;
                background-color: #fff;
                margin-bottom: 60px;
            }

            h2{
                font-size: 30px;
                color: #222;
            }

            .last-boys{
                display: flex;
                justify-content: space-between;
                color: #444;
                font-size: 14px;

                @media screen and (max-width: 450px) {
                    font-size: 12px;
                }
                p{
                    column-gap: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    text-align: left;
                    &:last-child{
                        justify-content: flex-end;
                    }
                }
            }

            form{
                padding: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                position: relative;
                z-index: 2;

                .image-holder{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                    flex-wrap: wrap;
                    padding: 50px 20px;
                    border-radius: 20px;
                    background-color: #000;
                    position: relative;
                    margin-top: 20px;
                    background-image: linear-gradient(89.85deg, #F2CB52 45.46%, #F28705 95.67%);
                    background: linear-gradient(-45deg, #F2CB52, #ffb60b, #F28705, rgb(255, 128, 0));
                    background-size: 400% 400%;
                    animation: gradient 15s ease infinite alternate;

                    @keyframes gradient {
                        0% {
                            background-position: 0% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                        100% {
                            background-position: 0% 50%;
                        }
                    }

                    &::before{
                        content: "Product Images";
                        position: absolute;
                        top: -30px;
                        color: #fff;
                        z-index: 10;
                        padding: 15px 20px;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: "Raleway", monospace;
                        border-radius: 100px;
                        background-color: rgb(230, 66, 37);
                        box-shadow: 0px 0px 0px 4px #fff;
                        backdrop-filter: blur(10px);
                    }

                    .image{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.5s ease;
                        border: 5px solid #000;
                        border-radius: 50%;
                        .add{
                            position: absolute;
                            z-index: 2;
                            color: #fff;
                            font-size: 30px;
                            transition: all 0.5s ease;
                            pointer-events: none;
                        }

                        img{
                            background-color: #0009;
                        }

                        &:hover{
                            .add{
                                transform: scale(1.3);
                            }
                        }

                        .remove{
                            background-color: rgb(230, 66, 37);
                            background-color: #000;
                            color: #fff;
                        }
                    }

                    img{
                        max-width: 100%;
                        transition: all 0.5s ease;

                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }

                .inpt{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    label{
                        font-weight: 500;
                    }

                    input, textarea{
                        padding: 15px;
                        border-radius: 10px;
                        border: none;
                        background-color: rgba(255, 166, 0, 0.072);
                        backdrop-filter: blur(10px);
                        transition: all 0.5s ease;

                        &#file-upload-button{
                            border: none;
                            background-color: rgb(255, 166, 0);
                            color: #fff;
                            font-size: 18px;
                            font-weight: 500;
                            font-family: "Raleway";
                            padding: 10px 20px;
                            transition: all 0.5s ease;
                            cursor: pointer;

                            &:hover{
                                padding: 10px 30px;
                                background-color: #222;
                            }
                        }

                        &:hover, &:focus{
                            background-color: rgba(255, 166, 0, 0.29);
                        }
                    }

                    textarea{
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        height: 100px;
                        min-height: 100px;
                        max-height: 300px;
                    }
                }

                button{
                    padding: 15px 20px;
                    background-color: rgb(116, 116, 215);
                    background-color: rgb(255, 166, 0);
                    border: none;
                    color: #fff;
                    font-weight: 500;
                    font-size: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    overflow: hidden;

                    &.reset__btn{
                        background-color: #d12d25;
                        gap: 10px;

                        &::before{
                            display: none;
                        }

                        &:hover{
                            background-color: #000;
                            color: red;
                        }
                    }

                    &.disabled{
                        opacity: 0.3;
                        cursor: not-allowed;
                    }

                    &::before{
                        content: "";
                        background-color: #fff3;
                        position: absolute;
                        width: 400px;
                        height: 400px;
                        border-radius: 50%;
                        opacity: 0;
                        transform: scale(0);
                        transition: all 0.5s ease;
                    }

                    &:hover{
                        &::before{
                            opacity: 1;
                            transform: scale(2);
                        }
                    }
                }

                .inpt-check{
                    display: flex;
                    gap: 10px;
                    flex-wrap: nowrap;

                    label{
                        font-size: 15px;
                        word-break: break-all;
                    }
                }
            }

            .image{
                width: fit-content;
                cursor: pointer;
                position: relative;
                img{
                    width: 150px;
                    // animation: unloaded-moving-z 1s ease forwards infinite alternate;
                    height: 150px;
                    min-width: 150px;
                    max-width: 150px;
                    min-height: 150px;
                    max-height: 150px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: top;
                    color: transparent;
                    background-color: rgba(195, 191, 182, 0.6);
                    border: none !important;
                }

                .remove{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 40px;
                    z-index: 2;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    backdrop-filter: blur(10px);
                    align-items: center;
                    border-radius: 100px;
                    background-color: #0004;
                    transition: all 0.5s ease;
                    color: #fff;

                    &:hover{
                        transform: rotate(360deg);
                    }
                }

                input{
                    display: none;
                }
            }
        }
    }

    
    .cart-big{
        display: flex;
        justify-content: flex-end;
        .content{
            width: calc(100% - 120px);
            padding-top: 130px;
            padding-bottom: 58px;
            padding-right: 25px;
            overflow-x: hidden;
            height: 100%;
            overflow-y: scroll !important;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            &::-webkit-scrollbar{
                width: 7px;
                height: 0px;
            }
            
            &::-webkit-scrollbar-thumb{
                background-color: rgba(255, 183, 0, 0.993);
                border-radius: 100px;
            }

            &::-webkit-scrollbar-button{
                height: 50px;
            }

            @media screen and (max-width: 660px) {
                width: 100%;
                padding-top: 100px;
                padding-right: 5vw;
                padding-left: 5vw;

                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }

                .product{
                    width: 100%;
                    max-width: 300px;
                }
            }
            
            .container{
                justify-content: center;
                width: 100%;
                @media screen and  (max-width: 660px) {
                    justify-content: center;
                }
                padding-bottom: 50px;

                &.empty{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    position: fixed;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    
                    svg{
                        color: rgb(255, 166, 0);
                        font-size: 100px;
                        animation: show-me 0.5s ease forwards;
                    }
                    
                    p{
                        line-height: 30px;
                        margin-top: 20px;
                        max-width: 80%;
                        animation: show-me 0.5s ease forwards;
                    }

                    a{
                        padding: 5px;
                        background-color: rgb(255, 183, 0);
                        text-decoration: none;
                        font-weight: 600;
                        font-family: "Raleway", monospace;
                        transition: all 0.5s ease;

                        &:hover{
                            background-color: tomato;
                            color: #fff;
                        }
                    }
                }
            }

            .summary{
                position: fixed;
                bottom: 20px;
                z-index: 10;
                width: calc(100% - 165px);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s ease;
                pointer-events: none;
                
                @media screen and (max-width: 660px) {
                    border-radius: 20px;
                    pointer-events: all;
                    padding: 20px 5vw;
                    box-shadow: 0px 0px 10px #0001;
                    background-color: #fff;
                }
                
                .holder{
                    background-color: #fff;
                    border-radius: 20px;
                    pointer-events: all;
                    padding: 20px;
                    box-shadow: 0px 0px 10px #0001;
                    width: 100%;
                    height: 100%;
                    max-width: 800px;
                    
                    @media screen and (max-width: 660px) {
                        padding: 0px;
                        border-radius: 0px;
                        box-shadow: 0px 0px 10px #0000;
                    }
                }
                
                .controls{
                    position: absolute;
                    top: -26px;
                    left: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;
                    transition: all 0.5s ease;

                    .hide-temporary{
                        position: relative;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100px;
                        // border: 2px solid;
                        background-color: rgb(255, 183, 0);
                        font-size: 20px;
                        pointer-events: all;
                        cursor: pointer;

                        &::before{
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            background-color: inherit;
                            transition: all 0.5s ease;
                            border-radius: inherit;
                            transform: scale(1);
                            z-index: -1;
                            animation: bubbly 1s ease forwards infinite ;
                            pointer-events: none;
                            background-color: transparent;
                        }

                        @keyframes bubbly {
                            0%{
                                transform: scale(0);
                                opacity: 1;
                            }

                            100%{
                                transform: scale(2);
                                opacity: 0;
                            }
                        }

                        svg{
                            color: #fff;
                            transition: all 0.5s ease;

                            @media screen and (max-width: 660px) {
                                transform: translateY(0) !important;
                            }
                        }

                        &:hover{
                            svg{
                                transform: translateY(5px);
                            }
                        }
                    }
                }

                &.empty{
                    transform: translateY(150%);
                    pointer-events: none;
                    visibility: hidden;
                    opacity: 0;
                }

                &.hideTemp{
                    transform: translateY(150%);

                    .controls{
                        transform: translateY(-170px);

                        .hide-temporary{
                            svg{
                                transform: translateY(0px) rotate(-180deg) !important;
                            }

                            &::before{
                                background-color: rgb(255, 183, 0);
                            }
                        }
                    }
                }

                @media screen and (max-width: 660px) {
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    padding: 15px 5vw;
                    border-radius: 20px 20px 0 0;
                }

                h2{
                    color: #222;
                    margin-bottom: 10px;
                    font-family: "Raleway", sans-serif;
                }
                
                .total{
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    p{
                        &:last-child{
                            font-weight: 600;
                            font-size: 20px;
                        }
                    }
                }
                
                .checkout{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    a, .gotoCheckout{
                        border: none;
                        max-width: 700px;
                        width: 100%;
                        padding: 15px 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        background-color: rgb(116, 116, 215);
                        background-color: rgb(255, 183, 0);
                        border-radius: 15px;
                        color: #fff;
                        font-family: "Raleway", monospace;
                        font-size: 23px;
                        font-weight: 600;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;

                        &::before{
                            content: "";
                            position: absolute;
                            width: 400px;
                            height: 400px;
                            border-radius: 50%;
                            transition: all 0.5s ease;
                            background-color: #fff3;
                            transform: scale(0);
                            opacity: 0;
                        }
                        
                        &:hover{
                            &::before{
                                opacity: 1;
                                transform: scale(2);
                            }
                        }

                        @media screen and (max-width: 660px) {
                            font-size: 20px;
                        }
                    }
                }
            }

            .heading{
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 30px;
                z-index: 4;
            }

            .container{
                display: flex;
                gap: 30px;
                flex-wrap: wrap;
                .product{
                    min-width: 230px;
                    border-radius: 20px;
                    background-color: #000;
                    position: relative;
                    transition: all 0.5s ease;
                    animation: show-me 0.5s ease forwards;
                    background-color: #4b473c;
                    background-image: linear-gradient(#0000, #000);
                    // overflow: hidden !important;
                    position: relative;

                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: #fff2;
                        z-index: 1;
                        animation: unloaded-movingx 1s ease forwards infinite alternate;
                    }

                    @media screen and (max-width: 400px){
                        max-width: 250px;
                        min-width: 230px;
                    }

                    @keyframes unloaded-movingx{
                        0%{
                            opacity: 1;
                        }
                        100%{
                            opacity: 0;
                        }
                    }

                    &:hover{
                        transform: translateY(-10px);
                        @media screen and (max-width: 660px) {
                            transform: translateY(0);
                        }
                    }

                    .top{
                        width: 100%;
                        height: 250px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding: 15px;
                        // background-color: #000;
                        border-radius: 20px;
                        position: relative;
                        overflow: hidden;
                        background-image: linear-gradient(#0000, #000), url(./assets/product-img1.jpeg);
                        background-size: cover;
                        background-position: top;
                        z-index: 2;

                        .product__link{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            background-color: #0008;
                            backdrop-filter: blur(10px);
                            font-size: 24px;
                            transition: all 0.5s ease;
                            opacity: 0;
                            visibility: hidden;
                            pointer-events: none;

                            @media screen and (max-width: 660px) {
                                opacity: 0;
                                visibility: visible;
                                pointer-events: all;
                                backdrop-filter: blur(0px);

                                &:hover{
                                    opacity: 0.4 !important;
                                }

                                svg{
                                    display: none;
                                }
                            }
                        }
                        
                        &:hover{
                            .product__link{
                                pointer-events: all;
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .product-details{
                            .product-name{
                                font-weight: 500;
                                color: #fff;
                                margin-bottom: 5px;
                            }

                            .product-price{
                                font-weight: 600;
                                font-family: "Poppins";
                                color: #fff;
                            }
                        }
                    }

                    .remove{
                        position: absolute;
                        top: -20px;
                        right: -14px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        border-radius: 50%;
                        z-index: 4;
                        cursor: pointer;
                        background-color: rgb(131, 21, 21);
                        transition: all 0.5s ease;
                        box-shadow: 0px 0px 0px 4px transparent;

                        &:hover{
                            transform: rotate(360deg);
                        }
                    }

                    &:hover{
                        .remove{
                            box-shadow: 0px 0px 0px 4px rgb(255, 166, 0);
                        }
                    }

                    .bottom{
                        width: 90%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 0;
                        margin-left: 5%;
                        margin-bottom: 10px;
                        background-color: rgb(116, 116, 215);
                        background-color: rgb(255, 166, 0);
                        color: #fff;
                        column-gap: 10px;
                        transition: all 0.5s ease;
                        border-radius: 15px;
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;

                        svg, span{
                            z-index: 1;
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            background-color: #fff3;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            z-index: 0;
                            transform: scale(0);
                            transition: all 0.5s ease;
                            opacity: 0;
                        }
                        
                        &:hover{
                            &::before{
                                transform: scale(3);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        @keyframes show-me {
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
    }

    .account{
        display: flex;
        justify-content: flex-end;
        .content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: calc(100%);
            padding-left: 120px;
            // max-width: 1320px;
            padding-top: 130px;
            padding-right: 25px;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
            &::-webkit-scrollbar{
                width: 5px;
                height: 0px;
            }

            &::-webkit-scrollbar-button{
                height: 100px;
            }
            
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background-color: #ffb700;
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-left: 0px;
                padding-top: 100px;
                padding-right: 0vw;
                padding-left: 0vw;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .heading{
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 1000px;
                margin-bottom: 30px;
                z-index: 4;

                a{
                    display: none;
                }

                @media screen and (max-width: 660px) {
                    padding: 0 5vw;
                }
            }

            .first-section{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 30px;
                width: 100%;
                max-width: 1000px;
                position: relative;
                min-height: fit-content;

                @media screen and (max-width: 660px) {
                    &::before, &::after{
                        display: none;
                    }
                    flex-direction: column;
                    overflow-x: visible;
                    padding: 0 5vw;
                }

                &::before{
                    content: "";
                    position: fixed;
                    height: 100%;
                    width: 20px;
                    display: none;
                    background-image: linear-gradient(90deg,#e9e9e9, transparent);
                    top: 0;
                    left: 120px;
                    z-index: 3;
                    pointer-events: none;
                }
                
                &::after{
                    content: "";
                    position: fixed;
                    height: 100%;
                    width: 30px;
                    display: none;
                    filter: blur(0px);
                    background-image: linear-gradient(90deg,transparent, #e9e9e9);
                    top: 0;
                    right: 25px;
                    z-index: 3;
                    pointer-events: none;
                }

                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb{
                    background-color: rgba(255, 183, 0, 0.413);
                    
                    &:hover{
                        background-color: rgba(255, 183, 0, 0.675);
                    }
                    border-radius: 100px;
                }

                .card{
                    width: 600px;
                    min-width: 600px;
                    background-color: #fff;
                    border-radius: 17px;
                    padding: 30px;
                    position: relative;

                    @media screen and (max-width: 860px) {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        padding: 20px;
                        margin-bottom: 20px;
                    }
                    
                    img{
                        min-width: 150px;
                        min-height: 150px;
                        width: 150px;
                        height: 150px;
                        object-fit: cover;
                        object-position: top;
                        border-radius: 50%;
                        margin-bottom: 20px;
                        animation: unloaded-moving-y 1s ease forwards infinite alternate;
                        background-color: rgb(255, 235, 197);
                        color: transparent;

                        @keyframes unloaded-moving-y {
                            0%{
                                background-color: rgb(255, 235, 197);
                            }
                            100%{
                                background-color: rgba(255, 235, 197, 0.4);
                            }
                        }

                        
                        @media screen and (max-width: 860px) {
                            min-width: 100px;
                            min-height: 100px;
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .edit--profile{
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background-color: #0003;
                        color: #fff;
                        font-size: 20px;
                        backdrop-filter: blur(10px);
                        z-index: 2;
                        transition: all 0.5s ease;

                        @media screen and (max-width: 660px) {
                            width: 30px;
                            height: 30px;
                            font-size: 17px;
                        }

                        &:hover{
                            transform: scale(1.2);
                            background-color: #0005;
                        }
                    }
                    
                    h3{
                        font-size: 24px;
                        color: #222;
                        margin-bottom: 10px;
                        font-family: "Raleway";
                        z-index: 4;

                        span{
                            color: rgb(46, 11, 71);
                        }
                    }
                    
                    p{
                        z-index: 4;
                        color: #444;
                        margin-bottom: 10px;
                    }
                    
                    .filler{
                        z-index: 4;
                        border-radius: 10px;
                        width: 100%;
                        background-color: rgba(255, 183, 0, 0.219);
                        background-color: rgba(0, 128, 0, 0.204);
                        background-color: #4bb54334;
                        margin-bottom: 30px;
                        overflow: hidden;

                        .circle{
                            width: 75%;
                            height: 100%;
                            background-color: rgb(255, 183, 0);
                            background-color: rgb(13, 144, 13);
                            background-color: #4BB543;
                            padding: 15px;
                            font-size: 23px;
                            color: #fff;
                            overflow: hidden;
                            font-weight: 600;
                            position: relative;
                            animation: increase2Percent 3s ease forwards;
                            @media screen and (max-width: 660px) {
                                font-size: 17px;
                            }

                            &:before{
                                content: "";
                                position: absolute;
                                top: 0;
                                height: 100%;
                                width: 100px;
                                transform: scaleY(1.5) rotate(-65deg) translateX(-10%);
                                transition: all 0.5s ease;
                                background-color: #fff2;
                                filter: blur(10px);
                                opacity: 0.5;
                                animation: filler 2s ease forwards infinite;

                                @keyframes filler {
                                    0%{
                                        left: -30%;
                                    }
                                    100%{
                                        left: 130%;
                                    }
                                }
                            }

                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                            }
                        }
                        
                        @keyframes increase2Percent {
                            0%{
                                width: 0%;
                                color: transparent;
                            }
                            100%{
                                width: 75%;
                                color: #fff;
                            }
                        }
                    }

                    .edit-account__link{
                        text-decoration: none;
                        position: absolute;
                        padding: 15px 30px;
                        background-color: rgb(116, 116, 215);
                        background-color: rgb(255, 166, 0);
                        color: #fff;
                        border-radius: 100px;
                        right: 20px;
                        bottom: -25px;
                        font-weight: 00;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        overflow: hidden;
                        font-size: 20px;

                        span, svg{
                            z-index: 2;
                            transition: all 0.5s ease;
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: all 0.5s ease;
                            width: 100%;
                            height: 100%;
                            transform: translateX(-110%);
                            background-color: #222;
                        }

                        &:hover{
                            &::before{
                                transform: translateX(0);
                            }

                            svg{
                                transform: rotate(360deg);
                            }
                        }
                    }
                }

                .two-fa{
                    min-width: 450px;
                    height: 240px;
                    background-image: linear-gradient(rgba(8, 8, 48, 0.47), black), url(https://velecor.com/wp-content/uploads/2020/10/Internet-security-threats.jpeg);
                    color: #fff;
                    background-size: cover;
                    padding: 30px;
                    border-radius: 15px;
                    position: relative;
                    overflow: visible;
                    padding-top: 70px;

                    @media screen and (max-width: 660px) {
                        min-width: 100%;
                        width: 100%;
                        padding-top: 60px;
                        .new{
                            transform: scale(0.8);
                        }
                    }

                    .remove{
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: all 0.5s ease;
                        width: 30px;
                        height: 30px;
                        opacity: 0;
                        border-radius: 50%;
                        background-color: red;

                        @media screen and (max-width: 660px) {
                            opacity: 1 !important;
                            background-color: transparent;
                        }
                        
                        &:hover{
                            background-color: red !important;
                            transform: rotate(360deg) scale(1.2);
                        }
                    }

                    &:hover{
                        .remove{
                            opacity: 1;
                        }
                    }

                    h2{
                        margin-bottom: 10px;
                    }

                    & > p{
                        color: #ddd;
                    }

                    a{
                        padding: 15px;
                        width: 140px;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        box-shadow: 0px 0px 10px #0004;
                        text-decoration: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        border-radius: 10px;
                        background-color: #fff;
                        transition: all 0.5s ease;
                        color: rgb(45, 2, 45);

                        svg{
                            &:last-child{
                                position: absolute;
                                right: 19px;
                                transform: translateX(30px);
                                opacity: 0;
                                transition: all 0.5s ease;
                            }
                        }

                        &:hover{
                            width: 180px;
                            svg{
                                &:last-child{
                                    opacity: 1;
                                    transform: translateX(0);
                                }
                            }
                        }
                    }



                    .new{
                        background-color: gold;
                        width: fit-content;
                        position: absolute;
                        padding: 15px;
                        color: tomato;
                        font-weight: 700;
                        border-radius: 100px;
                        top: 10px;
                        right: 10px;
                    }
                }
            }

            .second-section{
                z-index: 4;
                width: 100%;
                max-width: 1000px;
                @media screen and (max-width: 660px) {
                    padding: 0 5vw;
                }

                .title{
                    width: 100%;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2{
                        color: #222;
                    }

                    p{
                        width: 40px;
                        height: 40px;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        display: none;
                        border: 3px solid #000;
                        font-weight: 600;
                        font-family: "Poppins", "Raleway", monospace;
                    }
                }

                .container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    background-color: #fff;
                    @media screen and (max-width: 660px) {
                    }
                    margin-bottom: 30px;
                    border-radius: 15px;
                    overflow: hidden;

                    &::-webkit-scrollbar{
                        width: 0px;
                        height: 0px;

                        @media screen and (max-width: 660px) {
                            height: 0px;
                        }
                    }

                    &::-webkit-scrollbar-thumb{
                        background-color: rgba(255, 183, 0, 0.491);

                        &:hover{
                            background-color: rgb(255, 166, 0);
                        }
                    }

                    .empty{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 30px 0;
                        p{
                            line-height: 35px;
                            max-width: 80%;
                            font-size: 20px;
                            text-align: center;
                            animation: show-me 0.5s ease forwards;
                        }
    
                        a{
                            padding: 5px;
                            background-color: rgb(255, 183, 0);
                            text-decoration: none;
                            font-weight: 600;
                            font-family: "Raleway", monospace;
                            transition: all 0.5s ease;
    
                            &:hover{
                                background-color: tomato;
                                color: #fff;
                            }
                        }
                    }

                    .product{
                        width: 100%;
                        transition: all 0.5s ease;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 15px 20px;
                        border-bottom: 2px solid #eee;

                        &:last-child{
                            border-bottom: 0px solid transparent;
                        }

                        &:hover{
                            transform: scale(1) !important;

                            a{
                                opacity: 1;
                                transform: translateX(0);
                                pointer-events: all;
                            }
                        }

                        img{
                            background-color: #0008;
                            min-width: 45px;
                            max-width: 45px;
                            width: 45px;
                            height: 45px;
                            object-fit: cover;
                            border-radius: 200px;
                            box-shadow: 0px 0px 0px 2px #000;
                        }
                        
                        .name{
                            width: 100%;
                            margin-left: 20px;
                            font-size: 18px;
                            font-family: "Raleway", monospace;
                            font-weight: 500;
                            color: #222;

                            @media screen and (max-width: 500px) {
                                font-size: 17px;
                            }
                        }
                        
                        .price{
                            font-size: 22px;
                            font-weight: 600;
                        }

                        a{
                            position: absolute;
                            right: 15px;
                            transform: translateX(calc(100% + 200px));
                            pointer-events: none;
                            font-size: 18px;
                            font-weight: 600;
                            color: #fff;
                            color: rgb(255, 166, 0);
                            background-color: #222;
                            border-radius: 100px;
                            min-width: 40px;
                            padding: 10px 20px;
                            box-shadow: 0px 0px 10px #0003;
                            // height: 40px;
                            gap: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            transition: all 0.5s ease;

                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }

            .third-section{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                position: relative;
                top: 30px;
                z-index: 10;

                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    text-decoration: none;
                    color: #fff;
                    font-weight: 500;
                    padding: 20px 30px;
                    background-color: #000c;
                    backdrop-filter: blur(10px);
                    border-radius: 15px;
                    font-size: 20px;
                    transition: all 0.5s ease;

                    &:hover{
                        padding: 20px 40px;
                        background-color: #000;
                    }

                    svg{
                        color: rgb(255, 166, 0);
                        font-size: 25px;
                    }
                }
            }
        }
    }

    .become-a-seller-big{

        form{
            background-color: #fff;
            border-radius: 15px;
            margin-bottom: 50px;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            min-height: min-content;

            input{
                padding: 15px 20px !important;
                font-size: 18px;
            }

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                font-size: 20px;
            }

            .image.x{
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: center !important;
                align-items: center !important;
                max-width: 100%;
                margin-top: 10px;
                margin-bottom: 20px;
                width: 100%;
                position: relative;
                cursor: default;

                .pointer{
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    z-index: 1;
                    // box-shadow: 0px 0px 10px #fff;
                    color: #222;
                    font-size: 21px;
                    top: -10px;
                    background-color: #0004;
                    color: #fff;
                    backdrop-filter: blur(10px);
                    transform: translateX(45px);
                    cursor: pointer;
                }

                & > img{
                    border: 1px solid #0000 !important;
                    transition: all 0.5s ease;
                    cursor: pointer;
                    
                    &:hover{
                        border: 6px solid #0000 !important;
                    }
                }

                .avatars-holder{
                    position: absolute;
                    // bottom: -150px;
                    width: 100%;
                    min-height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    padding: 40px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    background-color: #000c;
                    backdrop-filter: blur(10px);
                    border-radius: 15px;
                    gap: 20px;
                    transition: all 0.5s ease;
                    transform: translateY(100%);
                    opacity: 0;
                    pointer-events: none;
                    visibility: hidden;

                    &::before{
                        content: "Select an Avatar";
                        position: absolute;
                        top: -20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        border-radius: 100px;
                        color: #fff;
                        background-color: rgb(255, 69, 37);
                    }

                    img{
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        max-width: 100%;
                        max-height: 100%;
                        min-width: 100%;
                        min-height: 100%;
                        object-fit: cover;
                        border: none !important;
                        background-color: transparent  !important;
                    }
                    
                    .img-holder{
                        width: 70px;
                        min-width: 70px;
                        cursor: pointer;
                        max-width: 70px;
                        height: 70px;
                        min-height: 70px;
                        max-height: 70px;
                        transition: all 0.5s ease;
                        box-shadow: 0px 0px 30px #fff0;
                        position: relative;
                        border-radius: 50%;
                        overflow: hidden;
    
                        &:hover{
                            box-shadow: 0px 0px 30px #fff9;
                        }

                        .selected{
                            opacity: 0;
                            transition: all 0.3s ease;
                            position: absolute;
                            color: #fff;
                            z-index: 2;
                            background-color: #0009;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            
                            svg{
                                font-size: 20px;
                                transition: all 0.3s ease;
                                transform: scale(0);
                            }
                        }
                        
                        &.active{
                            .selected{
                                opacity: 1;
                                
                                svg{
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                }

                &.hover{
                    .avatars-holder{
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                        pointer-events: all;
                    }
                }
            }

            .transaction{
                
                .heading{
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    padding-bottom: 20px;
                    border-bottom: 2px solid #ddd;

                    h3{
                        font-size: 24px;
                        font-family: "Raleway", monospace;
                    }

                    .corf{
                        display: flex;
                        margin-top: 10px;
                        gap: 10px;

                        & > div{
                            border-radius: 100px;
                            padding: 15px 20px;
                            min-width: 100px;
                            display: flex;
                            justify-content: center;
                            font-family: "Raleway", monospace;
                            font-size: 20px;
                            font-weight: 600;
                            color: #000;
                            background-color: rgba(255, 183, 0, 0.3);
                            cursor: pointer;
                            
                            &.active{
                                color: #fff;
                                background-color: rgb(255, 183, 0);
                            }
                        }
                    }
                }

                .body{
                    display: flex;
                    flex-direction: column;
                    
                    .crip-type{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;

                        p{
                            font-size: 19px;
                            font-weight: 600;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: fit-content;

                            a{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #555;
                                margin-left: 10px;
                                font-size: 20px;
                                transition: all 0.5s ease;
                                text-decoration: none;

                                &:hover{
                                    opacity: 0.7;
                                }
                            }
                        }

                        .current{
                            background-color: rgb(255, 183, 0);
                            color: #000;
                            font-weight: 700;
                            font-size: 25px;
                            padding: 10px 20px;
                            font-family: "Raleway", monospace;
                            border-radius: 10px;
                            box-shadow: -3px 3px #000;
                            transition: all 0.5s ease;
                            text-transform: uppercase;
                            cursor: pointer;
                            cursor: not-allowed;
                            
                            &:hover{
                                transform: translate(3px, -3px);
                                box-shadow: -6px 6px #000;
                            }
                        }
                    }

                    .wallet-address{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 10px;
                        margin-bottom: 40px;
                        // padding: 0px 5vw;
                        input{
                            width: 100%;
                            // max-width: 400px;
                            padding: 15px 20px;
                            border: none;
                            background-color: rgba(255, 183, 0, 0.15);
                            border-radius: 10px;
                            font-size: 18px;
                            color: #000;
                            transition: all 0.5s ease;

                            &:hover, &:focus{
                                background-color: rgba(255, 183, 0, 0.413);
                            }

                            &::placeholder{
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        
        h2{
            font-size: 30px;
            margin-bottom: 30px;
        }

        .content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 660px) {
                padding-left: 5vw !important;
                padding-right: 5vw !important;
            }
        }

        .mini-backdrop{
            position: fixed;
            z-index: 9;
            background-color: #000c;
            top: 80px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 8;
            display: none;
        }

        .holder{
            display: none;
            position: fixed;
            z-index: 10;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 40px;
            width: calc(100% - 10vw);
            max-width: 500px;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0px 0px 10px #0001;
            p{
                font-family: "Raleway", monospace;
                font-size: 17px;
                line-height: 25px;
                color: #222;
                text-align: center;
                line-height: 30px;

                a{
                    text-decoration: none;
                    transition: all 0.5s ease;
                    padding: 3px 5px;
                    margin: 0 3px;
                    color: #000;
                    background-color: rgb(255, 198, 54);

                    &:hover{
                        background-color: tomato;
                        color: #fff;
                    }
                }
            }

            .btns{
                width: 100%;
                display: flex;
                gap: 20px;
                justify-content: center;
                align-items: center;
                margin-top: 50px;

                button{
                    padding: 15px 30px;
                    border-radius: 100px;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-size: 20px;
                    background-color: #222;
                    color: #000;
                    background-color: rgb(255, 166, 0);
                    transition: all 0.5s ease;
                    cursor: pointer;
                    
                    &:hover{
                        padding: 15px 40px;
                        background-color: #222;
                        color: rgb(255, 166, 0);
                    }
                }

                a{
                    color: rgb(210, 38, 38);
                    text-decoration: none;
                }
            }
        }
    }

    .shop{
        margin-top: 50px;
    }
    .shop, .search-big, .become-a-seller-big{
        display: flex;
        justify-content: flex-end;
        .content{
            width: calc(100%);
            padding-left: 120px;
            padding-top: 130px;
            padding-right: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
            &::-webkit-scrollbar{
                width: 5px;
                height: 0px;
            }

            &::-webkit-scrollbar-button{
                height: 100px;
            }
            
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background-color: #ffb700;
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 100px;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .heading{
                margin-bottom: 20px;
                width: 100%;
                max-width: 1200px;
                h2{
                    color: #222;
                    font-size: 25px;
                }
                
                @media screen and (max-width: 660px) {
                    padding: 0 5vw;
                    span{
                        display: none;
                    }
                }

                .search-bar{
                    height: 45px;
                    padding: 0 15px;
                    width: 100%;
                    display: flex;
                    display: none;
                    flex-direction: row-reverse;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 15px;
                    background-color: #0003;
                    // border-radius: 10px;
                    border-left: 4px solid #000;
                    color: #000;
                    transition: all 0.5s ease;
        
                    input{
                        height: 100%;
                        width: 100%;
                        border: none;
                        background-color: transparent;
                        outline: none;
                        color: #000;

                        &::placeholder{
                            color: #000;
                        }
                    }
        
                    button{
                        background-color: transparent;
                        border: none;
                        color: #000;
                        min-width: 30px;
                        height: 30px;
                        background-color: #000;
                        color: rgb(255, 183, 0);
                        cursor: pointer;
                        transition: all 0.5s ease;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                @media screen and (max-width: 660px) {
                    h2{
                        margin-bottom: 15px;
                    }

                    .search-bar{
                        display: flex;
                    }
                }
            }

            .first-section{
                @media screen and (max-width: 660px) {
                    padding: 0 5vw 20px 5vw;
                }
                width: 100%;
                max-width: 1200px;
                padding-bottom: 20px;
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                z-index: 2;
                position: relative;
                border-bottom: 2px solid #0002;

                .title{
                    font-size: 17px;
                    font-weight: 500;
                    color: #666;
                    text-transform: capitalize;
                }

                .current{
                    font-family: "Raleway", sans-serif;
                    padding: 15px 20px;
                    min-width: 140px;
                    max-width: 140px;
                    width: 140px;
                    cursor: pointer;
                    background-color: rgb(116, 116, 215);
                    background-color: rgb(255, 166, 0);
                    color: #000;
                    font-weight: 500;
                    border-radius: 100px;
                    display: flex;
                    transition: all 0.5s ease;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    text-transform: capitalize;

                    svg{
                        font-size: 20px;
                        transition: all 0.5s ease;
                    }

                    &:hover{
                        svg{
                            transform: rotate(180deg);
                        }
                    }

                    &::before{
                        position: absolute;
                        content: "";
                        width: 120%;
                        height: 170%;
                    }

                    .categories{
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                    
                    &:hover{
                        .categories{
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                        }
                    }
                }

                .categories{
                    position: absolute;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    transition: all 0.5s ease;
                    top: 60px;
                    left: 0;
                    width: 100%;
                    border-radius: 20px;
                    backdrop-filter: blur(10px);
                    background-color: rgb(116, 116, 215);
                    background-color: rgb(255, 166, 0);
                    

                    button{
                        padding: 13px 0;
                        border: none;
                        color: #000;
                        border-bottom: 1px solid #0003;
                        background-color: transparent;
                        transition: all 0.5s ease;
                        &:hover, &:focus{
                            outline: none;
                            background-color: #fff3;
                            cursor: pointer;
                        }
                        
                        &:last-child{
                            border-bottom: 0px solid #fff4;
                        }
                    }
                }
            }

            .container, .second-section{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                row-gap: 30px;
                column-gap: 30px;
                margin-bottom: 70px;
                max-width: 1200px;
                @media screen and (max-width: 660px) {
                    &.container{
                        padding: 0 5vw;
                        display: grid;
                        row-gap: 15px;
                        column-gap: 15px;
                        grid-template-columns: repeat(2, 1fr);
                        max-width: 550px;
                    }
                }
                
                padding-bottom: 30px;

                
                .failed{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    text-align: center;
                    animation: show-me 0.4s ease forwards;
                    margin-bottom: 120px;

                    svg{
                        font-size: 100px;
                        color: red;
                    }

                    p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;
                    }

                    a{
                        padding: 5px;
                        font-weight: 500;
                        text-decoration: none;
                        background-color: rgb(255, 183, 0);
                        color: #000;
                        text-transform: capitalize;
                        font-family: "Raleway", monospace;
                        transition: all 0.5s ease;

                        &:hover{
                            background-color: tomato;
                            color: #fff;
                        }
                    }
                }

                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;

                    @media screen and (max-width: 660px) {
                        height: 0px;
                    }
                }

                .product{
                    min-width: 230px;
                    max-width: 230px;
                    border-radius: 20px;
                    background-color: #000;
                    transition: all 0.5s ease;
                    animation: show-me 0.4s ease forwards;
                    background-color: #4b473c;
                    background-image: linear-gradient(#0000, #000);
                    overflow: hidden !important;
                    position: relative;

                    &::before{
                        content: "";
                        position: absolute;
                        height: calc(100% + 40px);
                        transform: rotate(20deg);
                        width: 60px;
                        top: -20px;
                        background-color: #ffffff09;
                        z-index: 1;
                        filter: blur(10px);
                        animation: unloaded-moving 2s ease forwards infinite;
                    }

                    @keyframes unloaded-moving{
                        0%{
                            left: -120px;
                        }
                        100%{
                            left: calc(100% + 120px);
                        }
                    }

                    &:hover{
                        transform: translateY(-10px);
                    }

                    @media screen and (max-width: 660px) {
                        min-width: 100%;
                        max-width: 100%;
                        .product__link{
                            visibility: visible !important;
                            opacity: 0 !important;
                            backdrop-filter: blur(0px) !important;
                            svg{
                                display: none !important;
                            }
                        }
                        &:hover{
                            .product__link{
                                opacity: 0.4 !important;
                            }
                            transform: translateY(0px) !important;
                        }
                    }

                    .top{
                        width: 100%;
                        height: 230px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding: 15px;
                        // background-color: #000;
                        border-radius: 20px 20px 0 0;
                        position: relative;
                        overflow: hidden;
                        background-image: linear-gradient(#0000, #000), url(./assets/product-img1.jpeg);
                        background-size: cover;
                        background-position: top;
                        z-index: 2;

                        .product__link{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            background-color: #0008;
                            backdrop-filter: blur(10px);
                            font-size: 24px;
                            transition: all 0.5s ease;
                            opacity: 0;
                            visibility: hidden;
                            pointer-events: none;
                        }
                        
                        &:hover{
                            .product__link{
                                pointer-events: all;
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .product-details{
                            .product-name{
                                font-weight: 500;
                                color: #fff;
                                margin-bottom: 5px;
                            }

                            .product-price{
                                font-weight: 600;
                                font-family: "Poppins";
                                color: #fff;
                            }
                        }
                    }

                    .bottom{
                        width: 90%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        margin-left: 5%;
                        margin-bottom: 10px;
                        background-color: rgb(116, 116, 215);
                        background-color: rgb(255, 166, 0);
                        color: #000;
                        column-gap: 10px;
                        transition: all 0.5s ease;
                        border-radius: 10px;
                        cursor: pointer;
                        position: relative;
                        overflow: hidden;
                        height: 40px;

                        &.in-cart{
                            background-color: rgb(210, 38, 38);
                        }

                        svg, span{
                            z-index: 1;
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            background-color: #fff3;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            z-index: 0;
                            transform: scale(0);
                            transition: all 0.5s ease;
                            opacity: 0;
                            filter: blur(10px);
                        }
                        
                        &:hover{
                            &::before{
                                transform: scale(3);
                                opacity: 1;
                            }
                        }
                    }
                    
                    @media screen and (max-width: 500px) {
                        .top{
                            height: 210px;
                        }

                        .bottom{
                            font-size: 15px;
                            word-break: keep-all;
                            word-wrap: normal;
                        }
                    }

                    @media screen and (max-width: 450px) {
                        .top{
                            height: 200px;
                        }

                        .bottom{
                            font-size: 14px;
                            word-break: keep-all;
                            word-wrap: normal;
                        }
                    }

                    @media screen and (max-width: 420px) {
                        .top{
                            height: 50vw;
                        }

                        .bottom{
                            span{
                                display: none;
                            }
                        }
                    }
                }
            }

            
            @keyframes show-me {
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
        }
    }

    .dashboard{
        display: flex;
        justify-content: center;

        .content{
            width: calc(100%);
            max-width: 1000px;
            padding-left: 120px;
            padding-top: 130px;
            padding-right: 25px;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
            &::-webkit-scrollbar{
                width: 5px;
                height: 0px;
            }

            &::-webkit-scrollbar-button{
                height: 100px;
            }
            
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background-color: #ffb700;
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-left: 0px;
                padding-right: 5vw;
                padding-left: 5vw;
                padding-top: 100px;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .heading{
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;

                a{
                    padding: 17px 20px;
                    color: #fff;
                    text-decoration: none;
                    font-size: 20px;
                    font-family: "Raleway", monospace;
                    background-color: rgb(255, 166, 0);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    font-weight: 600;
                    border-radius: 100px;
                    transition: all 0.5s ease;

                    &:hover{
                        background-color: #222;
                        padding: 17px 30px;
                    }
                }
            }

            .first-section{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                max-width: 1000px;

                @media screen and (max-width: 660px) {
                    flex-direction: column;
                    align-items: center;
                }
                
                .left{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .right{
                    min-width: 230px;
                    background-color: #fff;
                    background-color: rgb(255, 157, 0);
                    border-bottom: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 230px;
                    border-radius: 15px;
                    font-size: 50px;
                    color: #222;
                    position: relative;
                    cursor: pointer;
                    text-decoration: none;
                    transition: all 0.5s ease;
                    svg{
                        transition: all 0.5s ease;
                        color: #222;
                    }
                    // box-shadow: 3px 3px #222;
                    box-shadow: 0px 0px #222;
                    
                    &:hover, &:focus{
                        color: #000;
                        svg{
                            color: #000;
                            transform: scale(1.3);
                        }
                        transform: translate(-3px, -3px);
                    }
                    
                    p{
                        position: absolute;
                        font-size: 20px;
                        bottom: 30px;
                        font-family: "Raleway", monospace;
                    }

                    @media screen and (max-width: 660px) {
                        min-width: 70px;
                        box-shadow: 0px 0px #222;
                        transform: translate(0, 0) !important;
                        height: 70px;
                        z-index: 10;
                        position: fixed;
                        bottom: 5vw;
                        right: 5vw;
                        font-size: 25px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.197);
                        color: #222;
                        border-radius: 50%;
                        p{
                            width: 160px;
                            font-weight: 600;
                            height: 100%;
                            top: 0;
                            left: -100px;
                            z-index: -1;
                            background-color: rgb(255, 166, 0);
                            border-radius: 100px;
                            padding: 20px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            // opacity: 0;
                            // transform: scaleX(0);
                            transition: all 0.5s ease;
                        }

                        &.hideP{
                            p{
                                pointer-events: none;
                                transform: translateX(-100px);
                                opacity: 0;
                            }
                        }
                        background-color: rgb(255, 166, 0);
                        
                        &:hover{
                            p{
                                background-color: #000;
                                color: rgb(255, 166, 0);
                            }
                            background-color: #000;
                            color: rgb(255, 166, 0);
                            
                            svg{
                                transform: scale(1);
                                color: rgb(255, 166, 0);
                            }
                        }
                    }
                }
                

                .earnings, .products, .purchased{
                    padding: 15px;
                    background-color: #fff;
                    border-radius: 15px;
                    width: 100%;
                    // max-width: 700px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    transition: all 0.5s ease;
                    cursor: default;

                    &:hover{
                        transform: translateX(10px);
                        
                        @media screen and (max-width: 660px){
                            transform: translateX(5px);
                        }
                    }

                    .svg{
                        min-width: 40px;
                        height: 40px;
                        border-radius: 100px;
                        background-color: rgb(116, 116, 215);
                        color: #fff;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    a{
                        min-height: 40px;
                        min-width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background-color: #0002;
                        color: #000;
                        transition: all 0.5s ease;
                        text-decoration: none;
                        
                        &:hover{
                            background-color: rgb(255, 183, 0);
                            color: #fff;
                        }
                    }

                    &.products{
                        .svg{
                            background-color: rgb(255, 89, 0);
                        }
                    }

                    &.purchased{
                        .svg{
                            background-color: rgb(13, 144, 13);
                        }
                    }
                    
                    .details{
                        width: 100%;
                        p{
                            color: #666;
                            font-size: 13px;
                            &:first-child{
                                font-weight: 600;
                                color: #000;
                                font-size: 18px;
                                margin-bottom: 4px;
                            }
                        }
                    }
                }
            }

            .second-section{
                margin-top: 40px;
                display: flex;
                padding-bottom: 100px;
                gap: 50px;

                @media screen and (max-width: 1065px) {
                    flex-direction: column;
                }

                .section-i, .section-ii{
                    width: 100%;
                    .top{
                        display: flex;
                        justify-content: space-between;
                        a{
                            text-decoration: none;
                        }
                        margin-bottom: 20px;
                    }

                    .bottom{
                        border-radius: 15px;
                        overflow: hidden;
                        .activity, .creators{
                            width: 100%;
                            background-color: #fff;
                            padding: 15px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 2px solid #0000000c;
                            transition: all 0.5s ease;
                            cursor: default;
                            cursor: pointer;
                            animation: show-activity 1s ease forwards;

                            @keyframes show-activity {
                                0%{
                                    opacity: 0;
                                }
                                100%{
                                    transform: translateY(0px);
                                }
                            }

                            &:hover{
                                @media screen and (max-width:660px){
                                    padding: 15px;
                                }

                                .actions{
                                    opacity: 1;
                                    transform: translateY(0);
                                }
                            }

                            &:last-child{
                                border: none;
                            }
                            position: relative;

                            .actions{
                                opacity: 1;
                                cursor: default;
                                transform: translateY(0);
                                position: relative;
                                background-color: tomato;
                                background-color: #000;
                                padding: 15px 20px;
                                border-radius: 100px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 15px;
                                z-index: 1;
                                position: absolute;
                                right: 15px;
                                transition: all 0.5s ease;
                                opacity: 0;
                                transform: translateY(100px);
                                
                                & > div{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                    cursor: pointer;
                                    transition: all 0.5s ease;
                                    font-size: 18px;
                                    font-weight: 600;
                                    font-family: "Raleway", monospace;
                                    color: rgb(255, 166, 0);

                                    &:hover{
                                        transform: scale(1.1);
                                    }
                                }
                            }

                            img{
                                margin-top: 5px;
                                box-shadow: 0px 0px 0px 2px #222;
                                width: 50px;
                                height: 50px;
                                min-height: 50px;
                                min-width: 50px;
                                max-height: 50px;
                                max-width: 50px;
                                border-radius: 50%;
                                margin-right: 20px;
                                transition: all 0.5s ease;
                                background-color: #000;
                            }

                            .details{
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                gap: 5px;
                                color: #000;
                                transition: all 0.5s ease;
                                
                                h3{
                                    font-weight: 600;
                                    font-size: 18px;

                                    @media screen and (max-width: 660px) {
                                        font-size: 16px;
                                    }
                                }
                                
                                p{
                                    color: #666;
                                    font-size: 15px;
                                    transition: all 0.5s ease;

                                    @media screen and (max-width: 660px) {
                                        font-size: 13px;
                                    }
                                }
                            }
                            .time{
                                font-size: 15px;
                                color: #666;
                                min-width: 100px;
                                text-align: right;
                                
                                @media screen and (max-width: 660px) {
                                    font-size: 12px;
                                    min-width: fit-content;
                                    margin-left: 10px;
                                }
                            }
                            a{
                                min-width: 40px;
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-decoration: none;
                                border-radius: 50%;
                                color: rgb(0, 3, 13);
                                transition: all 0.5s ease;
                                background-color: #0000000c;

                                &:hover{
                                    color: #fff;
                                    background-color: rgb(255, 183, 0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content{
        h2{
            font-family: "Raleway", monospace;
        }
    }

    .new-product-big{
        .info{
            padding: 30px 20px;
            border-left: 4px solid #000;
            margin-bottom: 10px;
            background-color: rgba(255, 183, 0, 0.398);

            span{
                font-size: 23px;
                font-weight: 700;
                margin: 0 5px;
            }
        }
        
        .category{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            padding-top: 30px;
            margin-top: 10px;
            border-top: 2px solid #eee;

            @media screen and (max-width: 820px) {
                flex-direction: column;
                gap: 20px;
            }
            
            label{
                font-size: 20px;
                font-weight: 500;
                font-family: "Raleway";
            }

            .select{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                @media screen and (max-width: 520px) {
                    padding: 0 15px;
                    flex-wrap: wrap;
                }


                &>div{
                    cursor: pointer;
                    padding: 15px;
                    border-radius: 100px;
                    background-color: rgba(255, 183, 0, 0.398);
                    font-weight: 500;
                    transition: all 0.3s ease;

                    &.active{
                        background-color: rgb(255, 166, 0);
                        color: #fff;
                        padding: 15px 20px;
                    }
                    
                    @media screen and (max-width: 520px) {
                        padding: 10px 15px;
                        
                        &.active{
                            background-color: rgb(255, 166, 0);
                            color: #fff;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }

    
    .become-seller-to-view{
        position: fixed;
        z-index: 90;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000e9;
        backdrop-filter: blur(10px);
        transition: all 0.3s ease;
        display: none;
        justify-content: center;
        align-items: center;
        // opacity: 0;
        // visibility: hidden;
        // pointer-events: none;

        &.show{
            display: flex;
            // opacity: 1;
            // visibility: visible;
            // pointer-events: all;
        }

        .gotohome{
            position: fixed;
            top: 10px;
            cursor: pointer;
            font-size: 22px;
            left: 10px;
            color: #fff;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .holder{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 660px) {
                padding: 0 5vw;
            }
            
            a{
                background-color: rgb(255, 166, 0);
                color: #000;
                padding: 20px 30px;
                font-size: 20px;
                text-decoration: none;
                border-radius: 100px;
                font-weight: 700;
                text-align: center;
                
                @media screen and (max-width: 660px) {
                    font-size: 17px;
                    padding: 20px;
                }
            }
        }
    }

    .big-img{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 20;
        background-color: #000d;
        backdrop-filter: blur(10px);
        top: 0;
        left: 0;
        transition: all 0.5s ease;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;

        .close{
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            font-size: 23px;
            background-color: #fff2;
            transition: all 0.5s ease;
            svg{
                color: #fff;
                transition: all 0.5s ease;
            }
            
            &:hover{
                background-color: rgb(255, 183, 0);
                svg{
                    color: #000;
                    transform: rotate(45deg);
                }
            }
        }

        .download{
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            font-size: 23px;
            background-color: #fff2;
            transition: all 0.5s ease;
            svg{
                color: #fff;
                transition: all 0.5s ease;
            }
            
            &:hover{
                svg{
                    color: #000;
                }
                background-color: rgb(255, 183, 0);
            }
        }
        
        .content{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 20px;
            transform: translateY(100vh);
            transition: all 0.5s ease;

            .controls{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }
            
            img{
                transition: all 0.5s ease;
                max-width: 1000px;
                max-width: 85%;
                min-height: 100px;
                max-height: 80%;
                object-fit: cover;
                object-position: top;
                background-color: #fff3;
                border-radius: 20px;
            }
        }

        &.show-me{
            opacity: 1;
            visibility: visible;
            pointer-events: all;

            .content{
                transform: translateY(0);
            }
        }
    }

    .seller{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 12;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: all 0.4s ease;
        padding: 20px;
        visibility: hidden;
        pointer-events: none;

        .bg{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 0.3s ease;
            background-color: #000c;
            backdrop-filter: blur(10px);
            pointer-events: none;
            opacity: 0;
        }

        .content{
            transition: all 0.3s ease;
            width: 100%;
            max-width: 500px;
            height: 100%;
            background-color: #fff;
            border-radius: 15px;
            transform: translateX(calc(110% + 40px));
            visibility: hidden;
            pointer-events: none;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 70px 20px;
            padding-top: 50px;

            & > div{
                animation: opacitatey 0.5s ease forwards;

                @keyframes opacitatey {
                    0%{
                        opacity: 0;
                    }
                    100%{
                        opacity: 1;
                    }
                }
            }

            .loader-holder{
                animation: none;
            }


            .heading{
                margin-bottom: 10px;

                p{
                    width: 140px;
                    height: 140px;
                    background-color: #0003;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    border-radius: 50%;
                }

                img{
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    background-color: #ccc;
                    pointer-events: none;
                }
            }

            .first-section{
                display: flex;
                flex-direction: column;
                text-align: center;
                h2{
                    font-size: 24px;
                    margin-bottom: 10px;
                }

                p{
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .second-section{
                // border-top: 2px solid #0002;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 20px 0;
                margin-top: 50px;
                border-radius: 20px;
                background-color: #0002;
                position: relative;
                
                h2{
                    font-size: 19px;
                    background-color: rgb(210, 38, 38);
                    background-color: #4BB543;
                    color: #fff;
                    padding: 10px 15px;
                    border-radius: 100px;
                    position: absolute;
                    top: -20px;
                    box-shadow: 0px 0px 0px 5px #fff;
                }
                
                .products{
                    width: 100%;
                    margin-top: 10px;
                    overflow: hidden;
                    padding: 0 20px;
                }

                .product{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 15px 0;
                    position: relative;
                    transition: all 0.5s ease;
                    border-bottom: 2px solid #fff;
                    margin: 5px 0;
                    
                    &:last-child{
                        border-bottom: 0px solid #fff;
                    }

                    img{
                        border-radius: 50%;
                        width: 45px;
                        height: 45px;
                        margin-right: 15px;
                        object-position: top center;
                        object-fit: cover;
                    }

                    p{
                        font-weight: 500;
                        font-size: 17px;
                        font-family: "Raleway", monospace;
                    }

                    a{
                        padding: 12px 17px;
                        color: rgb(255, 166, 0);
                        background-color: #222;
                        text-decoration: none;
                        transition: all 0.5s ease;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        border-radius: 100px;
                        transform: translateX(calc(100% + 200px));
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        right: 0;

                        &:hover, &:focus{
                            transform: scale(1.1);
                        }
                    }

                    &:hover{
                        a{
                            transform: translateX(0);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }



            & .loader{
                border: 6px solid #0001;
                border-bottom-color: rgb(255, 166, 0);
                border-radius: 50%;
                width: 50px;
                height: 50px;
                animation: spin-me 0.5s linear forwards infinite;

                @keyframes spin-me{
                    0%{
                        transform: rotate(-360deg);
                    }
                    100%{

                    }
                }
            }
        }

        .close{
            z-index: 2;
            position: fixed;
            transform: translateX(-200px);
            top: 20px;
            left: 20px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 100px;
            background-color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;
            svg{
                color: #222;
                font-size: 18px;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                // background-color: #000;
                svg{
                    transform: translateX(5px);
                    // color: rgb(240, 145, 50);
                }
            }
        }

        @media screen and (max-width: 850px) {
            padding: 0px;

            .bg{
                display: none;
            }

            .content{
                max-width: 100%;
                border-radius: 0px;
                transform: none;
                transform: scale(1);
                visibility: hidden;
                pointer-events: none;
                opacity: 0;
            }

            .close{
                transform: translateX(0);
                width: 45px;
                height: 45px;
                top: 10px;
                left: 10px;
                opacity: 0;
                background-color: #0007;
                backdrop-filter: blur(10px);
                transition: all 0.3s ease;
                overflow: hidden;
                
                svg{
                    font-size: 18px;
                    color: #fff;
                }
                
                &:hover, &:focus{
                    background-color: #000;
                    svg{
                        transform: translateX(5px);
                        color: rgb(240, 145, 50);
                    }
                }
            }
        }
    }

    &.preview-seller{
        .seller{
            opacity: 1;
            visibility: visible;
            pointer-events: all;

            .bg{
                opacity: 1;
                pointer-events: all;
                visibility: visible;
            }

            .content{
                transform: translateX(0);
                visibility: visible;
                pointer-events: all;
                opacity: 1;
            }

            .close{
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                pointer-events: all;
            }

            @media screen and (max-width: 850px) {
                .content{
                    transform: scale(1);
                }
        
            }
        }
    }

    .product-big{
        display: flex;
        justify-content: flex-end;
        .content{
            width: calc(100% - 120px);
            padding-top: 130px;
            padding-bottom: 100px;
            padding-right: 25px;
            position: relative;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                width: 5px;
                height: 0px;
            }

            &::-webkit-scrollbar-button{
                height: 100px;
            }
            
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background-color: #ffb700;
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-right: 0px;
                padding-top: 100px;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .purchase{
                position: fixed;
                bottom: 20px;
                width: calc(100% - 150px);
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                overflow: hidden;
                pointer-events: none;
                
                @media screen and (max-width: 660px) {
                    width: 100%;
                    padding: 0 5vw;
                }

                @media screen and (max-width: 450px) {
                    width: 100%;
                    padding: 0;
                    bottom: 0;
                }
                .price{
                    position: absolute;
                    opacity: 0;
                }
                .share{
                    height: 55px;
                    background-color: rgb(0, 3, 13);
                    min-width: 55px;
                    border-radius: 15px;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    z-index: 2;
                    font-size: 20px;
                    pointer-events: all;
                    svg{
                        transition: all 0.5s ease;
                    }

                    &:hover{
                        svg{
                            transform: rotate(360deg);
                        }
                    }

                    @media screen and (max-width: 450px) {
                        border-radius: 0px;
                        margin: 0;
                        height: 58px;
                        min-width: 58px;
                    }
                }
                .add-cart{
                    pointer-events: all;
                    box-shadow: 0px 0px 10px #0001;
                    max-width: 500px;
                    cursor: pointer;
                    width: 100%;
                    height: 55px;
                    display: flex;
                    justify-content: center;
                    padding: 0px 20px;
                    border-radius: 15px;
                    backdrop-filter: blur(10px);
                    background-color: rgb(255, 166, 0);
                    align-items: center;
                    gap: 10px;
                    overflow: hidden;
                    font-size: 20px;
                    font-weight: 500;
                    transition: all 0.5s ease;
                    
                    &.in-cart{
                        background-color: rgb(210, 38, 38);
                    }
                    
                    &.purchased{
                        background-color: #4BB543;                       
                    }
                    
                    @media screen and (max-width: 450px) {
                        border-radius: 0px;
                        height: 58px;
                        box-shadow: 0px 0px 10px #0002;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        width: 400px;
                        height: 400px;
                        background-color: #fff3;
                        transition: all 0.5s ease;
                        border-radius: 50%;
                        transform: scale(0);
                        opacity: 0;
                    }
                    
                    &:hover{
                        &::before{
                            transform: scale(2);
                            opacity: 1;
                        }
                    }
                }
            }

            .heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 40px;

                .author-info{
                    display: flex;
                    flex-direction: column;

                    .author{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;
                        text-decoration: none;
                        color: #000;
                        font-size: 16px;
                        padding: 10px 15px;
                        background-color: rgb(255, 166, 0);
                        border-radius: 100px;
                        transition: all 0.5s ease;
                        position: relative;
                        overflow: hidden;
                        border: none;
                        cursor: pointer;

                        &::before{
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #222;
                            left: -100%;
                            transition: all 0.5s ease;
                        }

                        button{
                            font-family: "Raleway", monospace;
                            font-weight: 600;
                            height: 40px;
                            min-width: 40px;
                            padding: 0 15px;
                            border: none;
                            background-color: #000;
                            color: #fff;
                            border-radius: 100px;
                            font-size: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.5s ease;
                            z-index: 3;
                            gap: 10px;

                            &:hover{
                                transform: scale(1.1);
                            }
                        }

                        span{
                            font-size: 17px;
                            z-index: 2;
                            position: relative;
                            font-weight: 600;
                        }

                        img{
                            z-index: 2;
                            min-width: 40px;
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            object-position: top;
                            border: 3px solid rgba(6, 3, 0, 0.665);
                            border-radius: 50%;
                            transition: all 0.5s ease;
                        }

                        &:hover{
                            color: #fff;
                            &::before{
                                left: 0;
                            }

                            img{
                                border: 0px solid transparent;
                            }

                            button{
                                color: #000;
                                background-color: #fff;
                                background-color: rgb(255, 183, 0);
                            }
                        }
                    }
                }

                                
                @media screen and (max-width: 660px) {
                    padding: 0 5vw;

                    h2{
                        margin-bottom: 20px;
                    }

                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .product-info{
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: 18px;
                letter-spacing: 0.7px;
                word-spacing: 1px;
                
                @media screen and (max-width: 660px) {
                    font-size: 17px;
                    letter-spacing: 0.5px;
                    word-spacing: 1px;
                }

                .about{
                    margin-top: 20px;
                    @media screen and (max-width: 660px) {
                        padding: 0 5vw;
                    }               
                }
                h3{
                    font-size: 22px;
                    font-family: "Raleway", sans-serif;
                    color: #333;
                    margin-top: 20px;
                    @media screen and (max-width: 660px) {
                        padding: 0 5vw;
                    }               
                }
                .product-images{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    overflow-x: scroll;
                    padding: 10px 0 20px 0;
                    @media screen and (max-width: 660px) {
                        padding: 10px 5vw 20px 5vw;
                    }

                    .media{
                        min-width: fit-content;
                        // width: 600px;
                        // max-width: 600px;
                        // width: fit-content;
                        height: 400px;
                        overflow: hidden;
                        border-radius: 20px;
                        position: relative;
                        background-color: rgb(195, 191, 182);
                        animation: unloaded-moving-z 1s ease forwards infinite alternate;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        @media screen and (max-width: 660px) {
                            height: 300px;
                        }

                        @media screen and (max-width: 380px) {
                            // min-width: 330px;
                            max-width: 430px !important;
                            min-width: fit-content;
                            height: 250px;
                            // width: fit-content;
                        }

                        @keyframes unloaded-moving-z {
                            0%{
                                background-color: rgb(195, 191, 182);
                            }
                            100%{
                                background-color: rgba(195, 191, 182, 0.6);
                            }
                        }

                        .options{
                            position: absolute;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background-color: #0005;
                            backdrop-filter: blur(10px);
                            font-size: 23px;
                            color: #fff;
                            cursor: pointer;
                            top: 10px;
                            right: 10px;
                            transition: all 0.5s ease;
                            opacity: 0;
                            pointer-events: none;
                            
                            @media screen and (max-width: 860px) {
                                pointer-events: all;
                                opacity: 0.4;
                            }
                        }
                        
                        &:hover{
                            .options{
                                pointer-events: all;
                                opacity: 1 !important;
                            }
                        }

                        img{
                            height: 100%;
                            // width: 100%;
                            object-fit: contain;
                            object-position: top center;
                            border-radius: inherit;

                            @media screen and (max-width: 660px) {
                                object-position: top left;
                            }

                            @media screen and (max-width: 380px) {
                                object-position: top center;
                            }
                        }
                        
                    }
                    
                    &::-webkit-scrollbar{
                        width: 0px;
                        height: 0px;
                    }

                }
                
                .highlights{
                    margin-bottom: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    @media screen and (max-width: 660px) {
                        padding: 0 5vw;
                        h3{
                            padding: 0;
                        }
                    }

                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: fit-content;
                        padding-left: 20px;
                        position: relative;
                        &::before{
                            content: "";
                            position: absolute;
                            height: 7px;
                            width: 7px;
                            left: 0;
                            top: 10px;
                            background-color: rgb(255, 166, 0);
                            border-radius: 100px;
                        }
                    }
                }

                .price{
                    margin-top: 10px;
                    padding: 5px 5px;
                    gap: 6px;
                    font-size: 20px;
                    font-weight: 600;
                    box-shadow: 0px 0px 10px #0001;
                    cursor: default;
                    display: flex;
                    justify-content: space-between;
                    min-width: 140px;
                    width: fit-content;
                    padding-left: 20px;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 100px;
                    font-family: "Raleway", monospace;
                    
                    @media screen and (max-width: 660px) {
                        margin-left: 5vw;
                    }

                    span{
                        padding: 10px 15px;
                        font-weight: 700;
                        background-color: rgb(255, 166, 0);
                        border-radius: 100px;
                        font-size: 27px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 10px;
                        cursor: pointer;
                        transition: all 0.5s ease;

                        &:hover{
                            background-color: #000;
                            padding: 10px 20px;
                            color: #fff;
                        }
                    }
                }

                .tags{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    flex-wrap: wrap;
                    display: none;
                    
                    .tag{
                        background-color: #0006;
                        color: #fff;
                        padding: 15px 20px;
                        border-radius: 100px;
                        cursor: pointer;
                        transition: all 0.5s ease;

                        &:hover{
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        
        
    }

    .footer{
        width: 100vw;
        position: relative;
        left: -45px;
        z-index: 4;
        padding: 60px 40px 100px 40px;
        border-radius: 0px !important;
        margin-bottom: 0px !important;
        background-color: rgb(255, 157, 0);
        background-color: #111;
        
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 50px;
        color: #fff;
        
        @media screen and (max-width: 1130px) {
            padding: 50px 30px 100px 30px;
            // justify-content: flex-start;
        }
        
        @media screen and (max-width: 900px) {
            padding: 50px 30px 100px 30px;
            gap: 40px;
            // justify-content: flex-start;
        }

        @media screen and (max-width: 980px) {
            justify-content: space-between;
            gap: 40px;
            flex-direction: column;
            padding-left: 120px;
            
            >div{
                width: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
    
                h3{
                    font-size: 25px !important;
                    width: 100%;
                    text-align: left;
                }
            }
        }
        
        @media screen and (max-width: 660px) {
            left: 0;
            padding: 50px 5vw 150px 5vw;
            border-radius: 0px;
            margin-bottom: 0px;
            gap: 40px;
            
            >div{
                width: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h3{
                    font-size: 25px !important;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .copyright{
            position: absolute;
            bottom: 30px;
            text-align: center;
            width: 100%;
            left: 0;
            justify-content: center;
            align-items: center;
        }
        
        &>div{
            h3{
                font-weight: 600;
                font-family: "Raleway", monospace;
                font-size: 27px;
                margin-bottom: 10px;

                @media screen and (max-width: 900px) {
                    font-size: 25px;
                }

                @media screen and (max-width: 800px) {
                    font-size: 23px;
                }
            }
            
            .links-holder{
                display: flex;
                flex-direction: column;
                gap: 10px;
                
                
                a{
                    width: fit-content;
                    color: #fff;
                    text-decoration: none;
                    
                    p{
                        font-size: 19px;
                        font-weight: 500;
                        font-family: "Raleway", monospace;
                        @media screen and (max-width: 900px) {
                            font-size: 18px;
                        }

                        @media screen and (max-width: 800px) {
                            font-size: 16px;
                        }
                    }
                    
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            
            &:first-child{
                .links-holder{
                    flex-direction: row;
                    flex-wrap: nowrap;
                    @media screen and (max-width: 1200px) {
                        gap: 7px;
                    }
                    @media screen and (max-width: 900px) {
                        gap: 5px;
                    }

                    @media screen and (max-width: 660px) {
                        gap: 10px;
                    }

                    
                    a{
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        transition: all 0.5s ease;
                        svg{
                            font-size: 19px;
                            transition: all 0.5s ease;
                        }

                        @media screen and (max-width: 800px) {
                            width: 25px;
                            height: 25px;
                        }

                        @media screen and (max-width: 660px) {
                            width: 30px;
                            height: 30px;
                        }
                        
                        &:hover{
                            transform: scale(1.1);
                            background-color: #fff;
                            svg{
                                color: rgb(255, 166, 0);
                                color: #000;
                            }
                        }

                        @media screen and (max-width: 900px) {
                            svg{
                                font-size: 18px;
                            }
                        }

                        @media screen and (max-width: 800px) {
                            svg{
                                font-size: 16px;
                            }
                        }

                        @media screen and (max-width: 660px) {
                            svg{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .faqs{
        display: flex;
        justify-content: flex-end;
        .content{
            width: calc(100%);
            padding-left: 120px;
            padding-top: 130px;
            padding-right: 25px;
            overflow-x: hidden !important;
            overflow-y: scroll !important;
            &::-webkit-scrollbar{
                width: 5px;
                height: 0px;
            }

            &::-webkit-scrollbar-button{
                height: 100px;
            }
            
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background-color: #ffb700;
            }
            
            @media screen and (max-width: 660px) {
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 100px;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .heading{
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 30px;

                h2{
                    font-size: 28px;
                    color: #333;
                    width: 100%;
                    padding: 0 5vw;
                    text-align: center;
                }

                p{
                    display: none;
                }
            }

            .container{
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                margin-bottom: 100px;
                
                @media screen and (max-width: 660px){
                    padding: 0 5vw;
                }
                
                .faq{
                    background-color: #fff;
                    border-radius: 15px;
                    position: relative;
                    height: 70px;
                    transition: all 0.5s ease;
                    overflow: hidden;
                    width: 100%;
                    max-width: 800px;

                    &:hover{
                        height: 230px;

                        @media screen and (max-width: 660px) {
                            height: 270px;
                        }

                        @media screen and (max-width: 450px) {
                            height: 300px;
                        }
                    }
                    
                    .faq-content{
                        padding: 20px;
                        transition: all 0.5s ease;
                    }
                    
                    .open-faq{
                        padding: 0 20px;
                        height: 70px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p{
                            font-size: 20px;
                            font-weight: 600;
                        }

                        svg{
                            transition: all 0.5s ease;
                            // transform: rotate(-180deg);
                        }

                        .open{
                            width: 40px;
                            height: 40px;
                            border-radius: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.5s ease;
                            cursor: pointer;
                            background-color: transparent;
                            
                            &:hover{
                                background-color: #0001;
                            }
                        }
                    }

                    &:hover{
                        .open-faq{
                            .open{
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }
        }
    }

    .home{
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        background-color: #e9e9e9 !important;
        & > .content{
            width: calc(100%);
            padding-left: 120px;
            padding-top: 10px;
            padding-right: 25px;
            margin-top: 130px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
            
            &::-webkit-scrollbar{
                width: 7px;
                height: 0px;
                
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 100px;
                background-color: rgb(255, 183, 0);
            }

            &::-webkit-scrollbar-button{
                height: 45px;
            }
            
            @media screen and (max-width: 660px) {
                padding-left: 0px;
                padding-right: 0px;
                margin-top: 150px;
                &::-webkit-scrollbar{
                    width: 0px;
                    height: 0px;
                }
            }

            .intro-section{
                width: 100%;
                max-width: 1000px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                @media screen and (max-width: 1100px) {
                    flex-direction: column;
                    .mySwiper{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 450px;

                        .product-img{
                            background-position: top center !important;
                        }
                    }
                    
                    .categoriex{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 17vw;
                        grid-template-columns: repeat(4, 1fr) !important;
                    }
                }
                
                @media screen and (max-width: 660px){
                    padding: 0 5vw;
                    
                    .mySwiper{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 350px;
    
                        .product-img{
                            background-position: top center !important;
                        }
                    }
                    
                    .categoriex{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 37vw;
                        grid-template-columns: repeat(2, 1fr) !important;
                    }
                }

                @media screen and (max-width: 430px){
                    padding: 0 5vw;
                    
                    .mySwiper{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 350px;
    
                        .product-img{
                            background-position: top center !important;
                        }

                        .hot-product{
                            .details{
                                h2{
                                    font-size: 19px;
                                }
                            }
                        }
                    }
                    
                    .categoriex{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 37vw;
                        grid-template-columns: repeat(2, 1fr) !important;
                    }
                }
                
                .mySwiper{
                    height: 400px;
                    width: 60%;
                    border-radius: 20px;
                    background-color: #000;

                    .swiper-button-prev, .swiper-button-next{
                        color:  rgb(255, 183, 0) !important;
                        transition: all 0.5s ease;
                        display: none;
                    }
                    
                    .swiper-pagination-bullet{
                        width: 8px;
                        height: 8px;
                        border-radius: 10px;
                        background-color:  rgb(255, 183, 0);
                        transition: all 0.5s ease;
                    }
                    
                    .swiper-pagination-progressbar-fill, .swiper-pagination-bullet-active{
                        background-color:  rgb(255, 183, 0);
                        width: 30px;
                    }

                    .hot-product{
                        position: relative;
                        color: #fff;
                        
                        &::before{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #0007;
                            background-image: linear-gradient(-150deg,#0000, #0008);
                            transition: all 0.5s ease;
                        }
                        
                        .details{
                            position: absolute;
                            bottom: 20px;
                            width: 100%;
                            z-index: 3;
                            padding: 20px;
                            pointer-events: none;
                            
                            h2{
                                font-weight: 400;
                                margin-bottom: 7px;
                                width: 100%;
                                
                                & > p{
                                    width: 100%;
                                    max-width: calc(100% - 100px);
                                }

                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 5px;

                                .likes{
                                    background-color: transparent;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 5px;

                                    p{
                                        font-family: "Raleway", monospace;
                                        font-size: 17px;
                                        color: #fff;
                                        font-weight: 500;
                                    }

                                    button{
                                        width: 30px;
                                        height: 30px;
                                        border: none;
                                        background-color: transparent;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        transition: all 0.5s ease;
                                        cursor: pointer;
                                        pointer-events: all;

                                        &:hover, &:focus{
                                            transform: scale(1.2);
                                        }

                                        svg{
                                            font-size: 20px;
                                            color: #fff7;
                                        }
                                        
                                        &.isLiked{
                                            svg{
                                                font-size: 19px;
                                                color: red;
                                            }
                                        }
                                    }


                                }
                            }
                            
                            .action{
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items:center;


                                p{
                                    font-size: 30px;
                                    font-weight: 600;
                                    padding: 5px 15px;
                                    background-color: #ffffff05;
                                    backdrop-filter: blur(10px);
                                    border-radius: 100px;

                                    @media screen and (max-width: 660px) {
                                        padding: 5px 10px;
                                        font-size: 23px;
                                    }
                                }
                                button{
                                    cursor: pointer;
                                    padding: 10px 15px;
                                    border: none;
                                    background-color:  rgb(255, 183, 0);
                                    border-radius: 5px;
                                    font-weight: 600;
                                    font-size: 18px;
                                    transition: all 0.5s ease;

                                    &:hover{
                                        padding: 10px 25px;
                                    }
                                }
                            }
                        }

                        .product-img{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-size: cover;
                            background-position: top center;
                            z-index: -2;
                            transition: all 0.5s ease;
                        }

                        .product-img.ii{
                            z-index: -1;
                            opacity: 0;
                        }

                        &:hover{
                            &::before{
                                opacity: 0.9;
                            }
                            .product-img.ii{
                                z-index: -1;
                                opacity: 1;
                            }

                            @media screen and (max-width: 660px){
                                .product-img.ii{
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    .hot{
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        background: #e74c3c;
                        color: #fff;
                        padding: 10px 15px;
                        border-radius: 100px;
                        z-index: 20;
                        font-size: 18px;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 7px;
                    }
                }

                margin-bottom: 40px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 60px;
                }

                .categoriex{
                    height: 100%;
                    width: 40%;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    position: relative;
                    gap: 7px;
                    

                    .a{
                        width: 100%;
                        height: 100%;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        text-decoration: none;
                        font-size: 17px;
                        font-family: "Raleway", monospace;
                        font-weight: 500;
                        transition: all 0.5s ease;
                        cursor: pointer;

                        &:hover{
                            background-color: #111 !important;
                        }

                        &:nth-child(1){
                            background: #3498db;
                            color: #fff;
                        }

                        &:nth-child(2){
                            background: #27ae60;
                            color: #fff;
                        }

                        &:nth-child(3){
                            background: #e74c3c;
                            color: #fff;
                        }
                        
                        &:nth-child(4){
                            background: #9b59b6;
                            color: #fff;
                        }
                    }

                    button{
                        position: absolute;
                        bottom: -25px;
                        padding: 10px 20px;
                        font-weight: 600;
                        width: calc(60%);
                        right: 20%;
                        border: none;
                        border-radius: 100px;
                        background-color: rgb(255, 183, 0);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        font-size: 18px;
                        overflow: hidden;

                        p, svg{
                            position: relative;
                            color: #000;
                            transition: all 0.5s ease;
                            z-index: 1;
                        }

                        &::before{
                            transition: all 0.5s ease;
                            width: 100%;
                            height: 100%;
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            background-color: #111;
                            pointer-events: none;
                            transform: translateX(-110%);
                        }

                        &:hover, &:focus{
                            &::before{
                                transform: translateX(0);
                            }
                            p, svg{
                                color: #fff;
                            }

                            svg{
                                transform: translateX(10px);
                            }
                        }

                        @media screen and (max-width: 600px) {
                            bottom: -60px;
                        }
                    }
                }
            }


            .landing-page{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .testimonial-h2{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                
                    &:hover{
                        .testi{
                            h2{
                                transform: translateX(0);
                            }    
                        }
                    }

                    @media screen and (max-width: 660px){
                        padding: 5vw;
                    }
                }

                .testi{
                    margin-top: 30px;
                    margin-bottom: 20px;
                    // text-transform: uppercase;
                    width: 100%;
                    max-width: 1000px;
                    position: relative;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    
                    h2{
                        font-size: 24px;
                        padding: 10px 20px;
                        color: #333;
                        border-radius: 100px;
                        border: 3px solid;
                        background-color: #e9e9e9;
                        // background-color: rgb(255, 166, 0);
                        z-index: 2;
                        position: relative;
                        transition: all 0.5s ease;
                        transform: translateX(-10vw);

                        @media screen and (max-width: 800px) {
                            font-size: 33px;
                        }
                        
                        @media screen and (max-width: 660px) {
                            background-color: transparent;
                            font-size: 37px;
                            transform: translateX(0);
                            border: none;
                            padding: 0px;
                        }
                    }
                    
                    @media screen and (max-width: 660px) {
                        &::before{
                            display: none;
                        }
                        margin-bottom: 0px;
                        background-color: #fff;
                        padding: 20px 0;
                        border-radius: 20px 20px 0 0;
                    }

                    
                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        background-color: #333;
                        border-radius: 100%;
                    }
                }  
            }

            .landing-page, .trending-products{
                @media screen and (max-width: 660px){
                    background-color: #e9e9e9 !important;
                }
            }

            .testimonials{
                border-left: 10px solid #000;
                width: 100%;
                min-height: 400px;
                max-width: 1000px;
                margin-bottom: 50px;
                position: relative;

                @media screen and (max-width: 660px) {
                    border: none;
                    border-top: 4px solid #222;
                    overflow: hidden;
                    border-radius: 0px 0px 20px 20px;
                }

                .circle{
                    position: absolute;
                    width: 100vw;
                    height: 100vw;
                    max-width: 500px;
                    max-height: 500px;
                    background-color: red;
                    top: -250px;
                    left: -250px;
                    display: none;
                    border: 20px solid #000;
                }
                
                img{
                    z-index: 10;
                    width: 100px;
                    bottom: 30px;
                    right: 100px;
                    background-color: transparent;
                    position: absolute;
                    z-index: 2;

                    @media screen and (max-width: 660px) and (min-width: 480px) {
                        width: 40px;
                        transform: scaleX(-1);
                        top: 13px;
                        left: 20px;
                    }

                    @media screen and (max-width: 480px) {
                        width: 40px;
                        top: 13px;
                        right: 20px;
                    }
                }

                .swiper-button-next, .swiper-button-prev{
                    transition: all 0.5s ease;
                }

                @media screen and (max-width:480px){
                    .swiper-button-next{
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        bottom: 20px;
                        top: calc(100% - 40px);
                        left: 57px;
                        border-left: 2px solid #fff1;
                        background-color: #111;
                        backdrop-filter: blur(10px);
                        border-radius: 0px 100px 100px 0px;
                        
                        &::after{
                            font-size: 25px;
                        }
                    }
                    .swiper-button-prev{
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        bottom: 20px;
                        top: calc(100% - 40px);
                        background-color: #111;
                        backdrop-filter: blur(10px);
                        border-radius: 100px 0px 0px 100px;

                        &::after{
                            font-size: 25px;
                        }
                    }
                }

                .swiper {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: #000;
                }
                
                .swiper-slide {
                font-size: 18px;
                color: #fff;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 40px 60px;
                position: relative;
                    @media screen and (max-width: 480px) {
                        padding: 20px 20px;
                    }


                    .image{
                        width: 100px;
                        height: 100px;
                        background-color: #fff;
                        border-radius: 50%;
                        position: absolute;
                        right: 50px;
                        top: 50px;
                        background-size: cover;
                        background-position: top center;
                        transform: none !important;
                        z-index: 2;

                        @media screen and (max-width:660px) {
                            width: 70px;
                            height: 70px;
                            border: 3px solid #000;
                            right: 30px;
                        }

                        @media screen and (max-width:480px) {
                            width: 70px;
                            height: 70px;
                            border: 3px solid #000;
                            position: relative;
                            top: 0;
                            left: 0;
                            margin-bottom: 10px;
                        }
                    }
                }
                
                .parallax-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 130%;
                height: 100%;
                -webkit-background-size: cover;
                background-color: rgb(255, 157, 0);
                // background-image: linear-gradient(rgba(255, 157, 0, 0.71), rgb(255, 157, 0)), url(./assets/feedback.jpg);
                background-size: cover;
                background-position: top;
                }
                
                .swiper-slide .title {
                    font-size: 41px;
                    font-weight: 300;

                    @media screen and (max-width:480px){
                        font-size: 30px;
                    }
                }
                
                .swiper-slide .subtitle {
                    font-size: 21px;
                    @media screen and (max-width:480px){
                        font-size: 18px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                }
                
                .swiper-slide .text {
                    font-size: 14px;
                    max-width: 400px;
                    line-height: 1.3;

                    @media screen and (max-width:480px){
                        font-size: 14px;
                        margin-bottom: 50px;
                    }
                }
            }

            .purchase-top{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                padding: 20px 0 50px 0;
                position: relative;
                max-width: 1000px;

                
                .heading{
                    width: fit-content;
                    max-width: 80%;
                    margin-top: 10px;
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    
                    
                    svg{
                        position: absolute;
                        font-size: 28px;
                        top: -6px;
                        left: -30px;
                        animation: spark 10s ease forwards infinite alternate;
                        @media screen and (max-width:660px){
                            display: none;
                        }
                    }

                    @keyframes spark {
                        0%{
                            opacity: 0;
                        }
                        20%{
                            opacity: 1;
                        }
                        100%{
                            opacity: 1;
                        }
                    }

                    h1{
                        font-family: "Raleway", sans-serif;
                        color: #222;
                        font-size: 40px;
                        margin-bottom: 30px;
                        text-align: center;

                        span{
                            background-color: rgb(255, 198, 54);
                            padding: 0 5px;
                            color: #000;
                        }
                    }

                }

                .second{
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    width: 100%;
                    max-width: 900px;
                    
                    img{
                        width: 50%;
                        max-width: 400px;
                        border-radius: 20px;
                    }
                    
                    .text{
                        width: 50%;
                        max-width: 400px;
                        display: flex;
                        flex-direction: column;
                        padding-top: 20px;
                        gap: 20px;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        
                        a{
                            padding: 20px 30px;
                            background-color: rgb(255, 157, 0);
                            color: #fff;
                            font-family: "Raleway", sans-serif;
                            font-size: 21px;
                            border-radius: 15px;
                            font-weight: 600;
                            text-align: center;
                            width: fit-content;
                            text-decoration: none;
                            transition: all 0.5s ease;
                            box-shadow: 0px 0px #222;

                            &:hover{
                                background-color: #111;
                                padding: 20px 40px;
                            }
                        }

                        span{
                            width: 100%;
                            display: flex;
                            gap: 10px;
                            font-family: "Raleway", monospace;
                            font-size: 17px;
                            font-weight: 500;
                            
                            svg{
                                display: none;
                                font-size: 25px;
                                color: rgb(75, 60, 35);
                            }

                        }
                    }
                    
                    
                    @media screen and (max-width: 660px) {
                        padding: 0 5vw;
                        flex-direction: column;
                        align-items: center;
                        gap: 0px;
                    
                        img{
                            width: 100%;
                            max-width: 400px;
                        }

                        .text{
                            text-align: center;
                            width: 100%;

                            span{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100% !important;
                            }
                        }
                    }
                    p{
                        font-family: "Raleway", monospace;
                        font-weight: 500;
                    }
                }
            }

            .last-section{
                font-size: 23px;
                margin-top: 50px;
                margin-bottom: 100px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                justify-content: center;
                align-items: center;
                text-align: center;
                

                @media screen and (max-width:660px) {
                    padding: 0 5vw;
                }

                p{
                    font-weight: 600;
                    font-family: "Raleway", sans-serif;
                    color: #444;
                }

                a{
                    padding: 15px 20px;
                    background-color: rgb(255, 166, 0);
                    border-radius: 15px;
                    text-decoration: none;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    overflow: hidden;
                    position: relative;
                    transition: all 0.5s ease;

                    p{
                        z-index: 2;
                        transition: all 0.5s ease;
                    }
                    
                    svg{
                        z-index: 2;
                        font-size: 18px;
                        transition: all 0.5s ease;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: #222;
                        top: 0;
                        left: -100%;
                        transition: all 0.5s ease;
                    }

                    &:hover{
                        p{
                            color: #fff;
                        }
                        svg{
                            color: #8a8585;
                        }
                        &::before{
                            left: 0;
                        }
                    }
                }
            }

            .trending-productss{
                width: 100%;
                margin-top: 10px;
                padding: 0 5vw;
            }

            .trending-products{
                max-width: 1000px;
                width: 100%;
                padding-top: 5px;

                .heading{
                    display: flex;
                    flex-direction: column;
                    font-size: 23px;
                    font-weight: 500;
                    font-family: "Raleway", monospace;
                    margin-bottom: 20px;
                    @media screen and (max-width: 660px) {
                        margin: 0 5vw;
                        margin-bottom: 20px;
                        margin-top: 20px !important;
                    }
                    gap: 5px;

                    .title{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                    }

                    p{
                        font-family: "Raleway", monospace;
                    }

                    .sub-title{
                        color: #777;
                        font-size: 17px;
                    }

                    .filter{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        @media screen and (max-width: 800px) {
                            display: none;
                        }
                        button{
                            padding: 10px 20px;
                            border: none;
                            border-radius: 100px;
                            background-color: rgba(255, 166, 0, 0.122);
                            transition: all 0.5s ease;
                            cursor: pointer;
                            
                            @media screen and (max-width: 660px) {
                                display: none;
                            }
                            
                            &.active, &:hover{
                                background-color: rgb(116, 116, 215);
                                background-color: rgb(255, 166, 0);
                                color: #fff;
                            }
                        }
                    }

                    a{
                        transition: all 0.5s ease;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        color: #444;
                        padding: 5px 7px;
                        border-radius: 0px 100px 100px 0px;
                        border-radius: 100px;
                        background-color: rgb(255, 183, 0);
                        &:hover{
                            transform: translateX(5px);
                        }
                    }
                }

                .container{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    overflow-x: scroll;
                    // flex-wrap: wrap;
                    row-gap: 20px;
                    column-gap: 20px;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    @media screen and (max-width: 660px) {
                        padding-bottom: 0px;
                        padding-left: 5vw;
                        padding-right: 5vw;
                        overflow-x: scroll;
                        flex-wrap: nowrap;
                    }

                    &::-webkit-scrollbar{
                        width: 0px;
                        height: 5px;

                        @media screen and (max-width: 660px) {
                            height: 0px;
                        }
                    }

                    &::-webkit-scrollbar-thumb{
                        border-radius: 100px;
                        background-color: rgba(255, 183, 0, 0.198);

                        &:hover{
                            background-color: rgb(255, 166, 0);
                        }
                    }

                    .product{
                        // width: 100%;
                        min-width: 230px;
                        max-width: 230px;
                        border-radius: 20px;
                        background-color: #000;
                        position: relative;
                        background-color: #4b473c;
                        background-image: linear-gradient(#0000, #000);
                        overflow: hidden;

                        &.course{
                            min-width: 350px;
                            max-width: 350px;
                            
                            @media screen and (max-width: 390px) {
                                min-width: 100%;
                                max-width: 350px;
                            }

                            .top{
                                height: 200px;
                            }
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            height: calc(100% + 40px);
                            transform: rotate(20deg);
                            width: 60px;
                            top: -20px;
                            background-color: #ffffff09;
                            z-index: 1;
                            filter: blur(10px);
                            animation: unloaded-moving 2s ease forwards infinite;
                        }

                        @keyframes unloaded-moving{
                            0%{
                                left: -120px;
                            }
                            100%{
                                left: calc(100% + 120px);
                            }
                        }
                        
                        .top{
                            width: 100%;
                            height: 230px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            padding: 15px 5%;
                            border-radius: 20px 20px 0 0;
                            position: relative;
                            overflow: hidden;
                            background-image: linear-gradient(#0000, #000), url(./assets/product-img1.jpeg);
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: top;
                            z-index: 1;

                            .product__link{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #fff;
                                top: 0;
                                left: 0;
                                z-index: 2;
                                background-color: #0008;
                                backdrop-filter: blur(10px);
                                font-size: 24px;
                                transition: all 0.5s ease;
                                opacity: 0;
                                visibility: hidden;
                                pointer-events: none;

                                @media screen and (max-width: 660px) {
                                    opacity: 0;
                                    visibility: visible;
                                    pointer-events: all;
                                    backdrop-filter: blur(0px);

                                    &:hover{
                                        opacity: 0.4 !important;
                                    }

                                    svg{
                                        display: none;
                                    }
                                }
                            }
                            
                            &:hover{
                                .product__link{
                                    pointer-events: all;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            .product-details{
                                .product-name{
                                    font-weight: 500;
                                    color: #fff;
                                    margin-bottom: 5px;
                                }

                                .product-price{
                                    font-weight: 600;
                                    font-family: "Poppins";
                                    color: #fff;
                                }
                            }
                        }

                        .bottom{
                            width: 90%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 0;
                            margin-left: 5%;
                            margin-bottom: 10px;
                            background-color: rgb(116, 116, 215);
                            background-color: rgb(255, 166, 0);
                            color: #000;
                            column-gap: 10px;
                            transition: all 0.5s ease;
                            border-radius: 15px;
                            cursor: pointer;
                            position: relative;
                            overflow: hidden;

                            &.in-cart{
                                background-color: rgb(210, 38, 38);
                            }

                            svg, span{
                                z-index: 1;
                            }

                            &::before{
                                content: "";
                                position: absolute;
                                background-color: #fff3;
                                width: 100px;
                                height: 100px;
                                border-radius: 50%;
                                z-index: 0;
                                transform: scale(0);
                                transition: all 0.5s ease;
                                opacity: 0;
                                filter: blur(10px);
                            }
                            
                            &:hover{
                                &::before{
                                    transform: scale(3);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

img{
    color: transparent !important;
}